import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { ConfigService } from 'app/service/config.service';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, throwError as observableThrowError } from 'rxjs';
import { map } from 'rxjs/operators';
import { SharedService } from '../service/common/shared.service';
import { HttpClient } from './../common/http-client';
import { Project as ProjectModel } from './../models/models';
import { Project } from 'app/models/project';

@Injectable()
export class ProjectService extends BehaviorSubject<GridDataResult> {
  private _projectsUrl = '';
  constructor(private configService: ConfigService, private _http: HttpClient, private sharedService: SharedService) {
    super(null);
    if (environment.localTesting) {
      this._projectsUrl = `${this.configService.config.serviceUrl}projects/`;
    } else {
      this._projectsUrl = `${this.configService.config.serviceUrl}asws/profishvb/shear_connector_projects/v1/`;
    }
  }

  private mapDate(item): void {
    item.createdOn = new Date(item.createdOn);
    item.updatedOn = new Date(item.updatedOn);
  }

  public query(state): void {
    this.sharedService.showLoader(true);
    this.getProjects(state)
      .subscribe(x => {
        x.data.map(this.mapDate);
        this.sharedService.showLoader(false);
        super.next(x);
      });
  }

  public searchForProject(projectName: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('query.pageNumber', '1');
    params = params.append('query.pageSize', '100');
    params = params.append('query.searchString', projectName);
    params = params.append('query.sortBy', 'name');
    params = params.append('query.shouldSortAscending', 'true');
    return this._http
      .get(`${this._projectsUrl}search`, params);
  }

  getProjects(state: any): Observable<GridDataResult> {
    let params = new HttpParams();
    params = params.append('query.pageNumber', state.pageNumber);
    params = params.append('query.pageSize', state.pageSize);
    params = params.append('query.searchString', state.searchString);
    params = params.append('query.sortBy', state.sortBy);
    params = params.append('query.shouldSortAscending', state.shouldSortAscending);
    return this._http
      .get<any>(`${this._projectsUrl}search`, params).pipe(map(response => {
        const result = {
          data: response.projects,
          total: response.totalCount
        };
        return <GridDataResult>result;
      }));
  }

  getsubProjects(projectId: number): Observable<Project> {
    return this._http.get(`${this._projectsUrl}${projectId}/subProjects`);
  }

  public getProjectById(id: number): Observable<any> {
    return this._http
      .get(`${this._projectsUrl}${id}`);
  }

  public updateProject(project: ProjectModel) {
    return this._http
      .put<ProjectModel>(`${this._projectsUrl}${project.id}`, project);
  }

  public createProject(project: ProjectModel) {
    return this._http
      .post(`${this._projectsUrl}`, project);
  }

  public setStatus(projectIds: number[], subProjectIds: number[], status: number) {
    return this._http
      .put(`${this._projectsUrl}/setStatus`, {
        status: status,
        projectIds: projectIds,
        subProjectIds: subProjectIds
      });
  }

  public getProjectJson(id: number, subProjectIds?: number[]) {
    const params = new HttpParams();
    subProjectIds.forEach(id => {
      params.append('subProjectIds', id.toString());
    });
    return this._http
      .get(`${this._projectsUrl}${id}/exportJson`, params);
  }


  public importProject(projectName: string, projectFile): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('uploadFile', projectFile, projectFile.name);
    let headers = new HttpHeaders();
    headers = headers.append('content-type', 'multipart/form-data');
    return this._http
      .post(`${this._projectsUrl}import?projectName=${projectName}`, formData, headers);
  }
}
