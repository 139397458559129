export class SessionStorageService {
  get<T>(key: string) {
    return <T>JSON.parse(sessionStorage.getItem(key));
  }

  set<T>(key: string, value: T) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  remove(key: string) {
    sessionStorage.removeItem(key);
  }
}


