import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NavService {
    private _navSource = new BehaviorSubject<Array<INavItem>>([]);
    public $navItemsChanged = this._navSource.asObservable();
    constructor() {

    }

    public setNav(navItems: Array<INavItem>) {
        this._navSource.next(navItems);
    }
}

export interface INavItem {
    routeUrl: string;
    routeName: string;
    navId: string; // page element identifier
}
