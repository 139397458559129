import { HttpClient as AngularHttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { UserService } from './../service/common/user.service';

@Injectable()
export class HttpClient {

  constructor(private http: AngularHttpClient, private userService: UserService) { }

  createAuthorizationHeader(headers: HttpHeaders) {
    if (this.userService.authentication && this.userService.authentication.accessToken) {
      headers = headers.append('Authorization', `Bearer ${this.userService.authentication.accessToken}`);
      if (environment.localTesting) {
        headers = headers.append('HC-UID', this.userService.authentication.userId);
        headers = headers.append('HC-User', this.userService.authentication.userName);
        headers = headers.append('HC-CustomerID', this.userService.authentication.company);
        headers = headers.append('HC-CustomerOriginID', this.userService.authentication.company);
        headers = headers.append('HC-Country', 'US');
        headers = headers.append('Access-Control-Allow-Origin', '*');
        headers = headers.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
      }
    }
    headers = headers.append('content-type', 'application/json');
    return headers;
  }

  public get<T>(url, params?: HttpParams, authorizationRequired = true): Observable<T> {
    let headers = new HttpHeaders();
    // IE 11 issue with caching
    if (/MSIE 9/i.test(navigator.userAgent) || /MSIE 10/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent)) {
      if (!params) {
        params = new HttpParams();
      }
      params = params.append('d', new Date().getTime().toString());
    }

    if (authorizationRequired) {
      headers = this.createAuthorizationHeader(headers);
    }
    const requestOption = Object.assign({}, { params: params }, { headers: headers });
    return this.http.get<T>(url, requestOption);
  }

  public post<T>(url, data, optionalHeaders?: HttpHeaders): Observable<T> {
    let headers = new HttpHeaders();
    headers = this.createAuthorizationHeader(headers);
    if (optionalHeaders) {
      optionalHeaders.keys().forEach(item => {
        headers = headers.delete(item);
        if (item !== 'content-type') {
          headers = headers.append(item, optionalHeaders.get(item));
        }
      });
    }
    const requestOption = Object.assign({}, optionalHeaders, { headers: headers });
    return this.http.post<T>(url, data, requestOption);
  }
  public put<T>(url, data): Observable<T> {
    let headers = new HttpHeaders();
    headers = this.createAuthorizationHeader(headers);
    return this.http.put<T>(url, data, {
      headers: headers
    });
  }
  public delete(url) {
    let headers = new HttpHeaders();
    headers = this.createAuthorizationHeader(headers);
    return this.http.delete(url, {
      headers: headers
    });
  }
}
