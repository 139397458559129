import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { SearchService } from './search.service';
import * as models from './../../models/models';
import * as _ from 'lodash';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  searchgroup: FormGroup;
  searchBox: FormControl;
  @Input() placeHolder: string;
  constructor(private searchService: SearchService) {

  }
  ngOnInit() {
    this.searchgroup = new FormGroup({ searchBox: new FormControl() });
  }
  ngOnDestory() {
  }

  public searchbtn() {
    const txtdata = this.searchgroup.get('searchBox').value;
    if (txtdata) {
      this.searchService.searchEvent.emit(txtdata);
    } else {
      this.searchService.searchEvent.emit('');
    }
  }

}
