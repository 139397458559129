import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {
  public get currentUrl() {
    return new URL(window.location.href);
  }

  public get currentPath() {
    return this.currentUrl.pathname;
  }

  public buildRedirectUri(path: string) {
    return window.location.origin + path;
  }
}
