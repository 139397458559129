import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SubProjectComponent } from './sub-project.component';
import { Observable } from 'rxjs';

@Injectable()
export class SubProjectSaveGuard implements CanDeactivate<SubProjectComponent> {
    constructor() {

    }

    canDeactivate(component: SubProjectComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        if (component.subProjectForm.dirty) {
            component.showSaveOnDestroyDialog = true;
            return component.$saveOnLeaveObs;
        } else {
            return true;
        }
    }
}
