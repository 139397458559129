import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/service/config.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class EulaService {
  private _eulaUrl = './assets/eula/';
  constructor(private _http: HttpClient, private configService: ConfigService) {
  }

  public getEulaText(eulaFile: string) {
    return this._http
      .get(`${this.configService.config.eulaUrl}${eulaFile}`, {responseType: 'text'})
       .pipe(catchError(error => {
         return this._http.get(`${this._eulaUrl}eula_en_GB.htm`, {responseType:  'text' });
       }));
  }
}
