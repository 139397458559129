import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'valuesBetween'
})
export class ValuesBetweenPipe implements PipeTransform {
    transform(value: string, minvalue: any, maxvalue: any, unit: any = '') {
        return value.replace('%MINVALUE%', minvalue).replace('%MAXVALUE%', maxvalue).replace('%UNIT%', unit);
    }
}
