import { Injectable, OnInit } from '@angular/core';
import { ConfigService } from 'app/service/config.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from './../common/http-client';
import * as models from './../models/models';
import { UserService } from './../service/common/user.service';

@Injectable()
export class CalculationsService implements OnInit {

  private _calculationsUrl = '';
  // Need to send length in meters and force in Newtons
  constructor(private configService: ConfigService, private _http: HttpClient, private user: UserService) {
    if (environment.localTesting) {
      this._calculationsUrl = `${this.configService.config.serviceUrl}calculations/`;
    } else {
      this._calculationsUrl = `${this.configService.config.serviceUrl}asws/common/shear_connector_calculation/v1/`;
    }
  }

  ngOnInit(): void {
    console.log('User Data = ', this.user);
  }


  /**
   * Do not change any thing this drawing is in milli meters and is set in the sub project
   * @param steelSectionDrawing
   */
  getSteelSectionDrawing(steelSectionDrawing: models.SteelSectionDrawing): Observable<string> {
    return this._http
      .post<string>(`${this._calculationsUrl}steelSectionDrawing`, steelSectionDrawing);
  }


  /**
   *
   * @param beamGeometryDrawing
   */
  getBeamGeometryDrawing(beamGeometryDrawing: models.BeamGeometryDrawing): Observable<string> {
    return this._http
      .post<string>(`${this._calculationsUrl}beamGeometryDrawing`, beamGeometryDrawing);
  }

  /**
   * Do not change any thing this drawing is in milli meters and is set in the sub project
   * @param compositeCrossSectionDrawing
   */
  getCompositeCrossSectionDrawing(compositeCrossSectionDrawing: models.CompositeCrossSectionDrawing): Observable<string> {
    return this._http
      .post<string>(`${this._calculationsUrl}compositeCrossSectionDrawing`, compositeCrossSectionDrawing);
  }

  getLWCDensity(value: number): Observable<models.AgregatedDensityResult> {
    return this._http
      .post<models.AgregatedDensityResult>(`${this._calculationsUrl}density`, { density: value });
  }

  /**
   * Do not change any thing this drawing is in milli meters and is set in the sub project
   * @param compositeBeamDrawing
   */
  getCompositeBeamDrawing(compositeBeamDrawing: models.CompositeBeamDrawing): Observable<string> {
    return this._http
      .post<string>(`${this._calculationsUrl}compositeBeamDrawing`, compositeBeamDrawing)
  }

  getConnectionZonesDrawing(connectionZonesDrawing: models.Calculate.ConnectionZonesDrawing): Observable<string> {
    return this._http
      .post<string>(`${this._calculationsUrl}connectionZonesDrawing`, connectionZonesDrawing);
  }

  /**
   * Do not change any thing this drawing is in milli meters and is set in the sub project
   * @param slabDrawing
   */
  getSlabDrawing1(slabDrawing: models.SlabDrawing): Observable<string> {
    return this._http
      .post<string>(`${this._calculationsUrl}slabDrawing1`, slabDrawing)
  }

  /**
   * Do not change any thing this drawing is in milli meters and is set in the sub project
   * @param slabDrawing
   */

  getSlabDrawing2(slabDrawing: models.SlabDrawing): Observable<string> {
    return this._http
      .post<string>(`${this._calculationsUrl}slabDrawing2`, slabDrawing);
  }

  getBeamLoadDrawing(beamLoadDrawing: models.BeamLoadDrawing): Observable<string> {
    return this._http
      .post<string>(`${this._calculationsUrl}beamLoadDrawing`, beamLoadDrawing)
  }

  /**
   * Do not change any thing this drawing is in milli meters and is set in the sub project
   * @param profiledSheetingDrawing
   */
  getProfiledSheetingDrawing(profiledSheetingDrawing: models.ProfiledSheetingDrawing): Observable<string> {
    return this._http
      .post<string>(`${this._calculationsUrl}profiledSheetingDrawing`, profiledSheetingDrawing);
  }

  getLoadDrawing(loadDrawing: models.LoadDrawing): Observable<string> {
    return this._http
      .post<string>(`${this._calculationsUrl}loadsDrawing`, loadDrawing);
  }

  getCalculationResults(subProject: models.Calculate.CreateCommand): Observable<models.Calculate.CalculationResult> {
    return this._http
      .post<models.Calculate.CalculationResult>(`${this._calculationsUrl}calculate`, subProject);
  }

  getdefaultCompositeLiveLoad(defaultCompositeLiveLoad: models.DefaultCompositeLiveLoad): Observable<number> {
    return this._http
      .post<number>(`${this._calculationsUrl}defaultCompositeLiveLoad`, defaultCompositeLiveLoad);
  }

  getCompositeSelfWeight(compositeSelfWeight: models.CompositeSelfWeight): Observable<number> {
    return this._http
      .post<number>(`${this._calculationsUrl}compositeSelfWeight`, compositeSelfWeight);
  }

  getReport(reportData: models.Calculate.CreateCommand): Observable<models.Calculate.CalculationResult> {
    return this._http
      .post<models.Calculate.CalculationResult>(`${this._calculationsUrl}report`, reportData);
  }

  getDxf(dxfDrawing: models.Calculate.DXFDrawing): Observable<models.Calculate.DxfResult> {
    return this._http
      .post<models.Calculate.DxfResult>(`${this._calculationsUrl}exportDxf`, dxfDrawing);
  }

  getBillOfMaterials(bomData: models.Calculate.BOMInput): Observable<models.Calculate.BOMResult> {
    return this._http
      .post<models.Calculate.BOMResult>(`${this._calculationsUrl}billOfMaterials`, bomData);
  }

}
