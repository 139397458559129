import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { UserService } from '../service/common/user.service';
import { SubProjectService } from '../subproject/sub-project.service';
import * as models from './../models/models';
import { ProjectService } from './../project/project.service';
import { SharedService } from './../service/common/shared.service';
import { SubProjectStatus } from './sub-project-status';
@Component({
  selector: 'sub-project-list',
  templateUrl: './sub-project-list.component.html',
  styleUrls: ['./sub-project-list-component.scss']
})
export class SubProjectListComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input()
  public project: any;
  @Input()
  public newenabled = false;
  @Input()
  public selectProjectEvent: any;
  @Input()
  public showDeleteButton = true;
  @Input()
  public selectedProjects: Map<number, models.GridProject> = new Map<number, models.GridProject>();
  @Input()
  public updateListEvent?: Subject<void>;
  @Input()
  public subprojects?: models.SubProject[];

  @Output()
  select = new EventEmitter();

  public showNewSubProjectDialog = false;
  public newSubProjectName: string;
  public language: string;
  public selectedSubProjectNames: string[];
  public selectedSubProjectIds: number[];
  public showDeleteDialog: boolean;
  public locale: string;
  public sort: SortDescriptor[] = [];
  public gridView: any;
  public skip = 0;
  public pazeSize = 4;
  public isProjectSelected: boolean;
  public disableCreateButton: boolean;
  private updateListSubscription: Subscription;


  constructor(private service: ProjectService,
    public translate: TranslateService,
    private subProjectService: SubProjectService,
    private router: Router,
    private sharedService: SharedService,
    private user: UserService,
    public myElement: ElementRef) {
    this.selectedSubProjectIds = [];
    this.selectedSubProjectNames = [];
    this.isProjectSelected = false;
    this.disableCreateButton = false;
  }

  public ngOnInit(): void {
    if (!this.subprojects) {
      this.loadSubprojects();
    }
    else {
      this.gridView = this.subprojects;
      if (this.selectedProjects.has(this.project.id)) {
        if (this.selectedProjects.get(this.project.id).selected) {
          this.isProjectSelected = true;
        }
      }
    }

    this.language = this.user.defaultSettings && this.user.defaultSettings.language ? this.user.defaultSettings.language :
      window.navigator.language;
    this.locale = window.navigator.language; // The date formats will be displayed in browser's locale always
    if (this.updateListEvent) {
      this.updateListSubscription = this.updateListEvent.subscribe(() => {
        this.loadSubprojects();
      });
    }
  }

  private loadSubprojects(): void {
    if (this.project.id) {
      this.service.getsubProjects(this.project.id)
        .subscribe(data => {
          data.subProjects.map(this.mapDate);
          this.gridView = data.subProjects;
          if (this.selectedProjects.has(this.project.id)) {
            if (this.selectedProjects.get(this.project.id).selected) {
              this.isProjectSelected = true;
            }
          }
        });
    }
  }

  ngAfterViewChecked() {
    if (this.isProjectSelected) {
      this.isProjectSelected = false;
      const checkboxes = this.myElement.nativeElement.getElementsByClassName('k-checkbox');
      for (let i = 0; i < checkboxes.length; i++) {
        if (!checkboxes[i].checked) {
          checkboxes[i].click();
        }
      }
    } else if (this.selectProjectEvent) {
      if (this.selectProjectEvent.selectedRows.some(p => p.dataItem.id === this.project.id)) {
        const checkboxes = this.myElement.nativeElement.getElementsByClassName('k-checkbox');
        for (let i = 0; i < checkboxes.length; i++) {
          if (!checkboxes[i].checked) {
            checkboxes[i].click();
          }
        }
      } else if (this.selectProjectEvent.deselectedRows.some(p => p.dataItem.id === this.project.id)) {
        const checkboxes = this.myElement.nativeElement.getElementsByClassName('k-checkbox');
        for (let i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].checked) {
            checkboxes[i].click();
          }
        }
      }
    }
  }

  public mapDate(item): void {
    item.createdOn = new Date(item.createdOn);
    item.updatedOn = new Date(item.updatedOn);
  }

  public onSubProjectSelect(subProject) {
    this.router.navigate(['/subproject', { projectid: this.project.id, id: subProject.id }]);
  }

  public addNewSubProject() {
    this.newSubProjectName = '';
    this.showNewSubProjectDialog = true;
  }

  public createNewSubProjectAndOpen = (): void => {
    if (this.newSubProjectName && this.newSubProjectName.length >= 3) {
      this.disableCreateButton = true;
      this.sharedService.showLoader(true);
      this.subProjectService.createSubProject(<models.SubProject><any>{
        projectId: this.project.id,
        name: this.newSubProjectName
      })
        .subscribe(
          (project) => {
            this.onNewSubProjectComplete(project);
            this.disableCreateButton = false;
          },
          (error: any) => {
            this.sharedService.popFailure(error);
            this.disableCreateButton = false;
          });
    }
  }

  public newSubProjectInvalid = (): boolean => {
    if (this.disableCreateButton) {
      return true;
    }
    const foundIndex = _.findIndex(this.gridView, (item) => {
      return (<string>(<any>item).name).toLowerCase() === this.newSubProjectName.toLowerCase();
    });
    return !this.newSubProjectName || this.newSubProjectName.length < 3 || foundIndex >= 0;
  }

  public onNewSubProjectComplete(subProject: models.SubProject) {
    this.router.navigate(['/subproject', { projectid: this.project.id, id: subProject.id, isNew: true }]);
  }

  public deleteSubProject(subProject) {
    this.showDeleteDialog = true;
  }

  public onDelete(ids: number[]) {
    this.setStatus(ids, SubProjectStatus.deleted);
    this.selectedSubProjectIds = [];
    this.selectedSubProjectNames = [];
  }

  private setStatus(id: number[], status: number): void {
    this.showDeleteDialog = false;
    this.subProjectService.setStatus(id, status)
      .subscribe(
        () => this.onSubProjectStatusChangeSuccess(status),
        (error: any) => this.sharedService.popFailure(error)
      );
  }

  private onSubProjectStatusChangeSuccess(status) {
    if (status === SubProjectStatus.deleted) {
      this.sharedService.popSuccess('sub-project-delete');
    }
    if (this.project.id) {
      this.service.getsubProjects(this.project.id)
        .subscribe(data => {
          data.subProjects.map(this.mapDate);
          this.gridView = data.subProjects;
        });
    }
  }


  public onSubProjectSelected(event: any) {
    if (event.deselectedRows.length > 0) {
      event.deselectedRows.forEach(row => {
        const nameIndex = this.selectedSubProjectNames.indexOf(row.dataItem.name);
        if (nameIndex > -1) {
          this.selectedSubProjectNames.splice(nameIndex, 1);
        }
        const idIndex = this.selectedSubProjectIds.indexOf(row.dataItem.id);
        if (idIndex > -1) {
          this.selectedSubProjectIds.splice(idIndex, 1);
        }
      });
    }
    if (event.selectedRows.length > 0) {
      event.selectedRows.forEach(row => {
        this.selectedSubProjectNames.push(row.dataItem.name);
        this.selectedSubProjectIds.push(row.dataItem.id);
      });
    }
    const subProjectCount = this.myElement.nativeElement.getElementsByClassName('k-checkbox').length;
    this.select.emit({ projectId: this.project.id, projectName: this.project.name, selectedSubProjectsIds: this.selectedSubProjectIds, subProjectCount: subProjectCount });
  }

  ngOnDestroy() {
    this.select.emit({ projectId: this.project.id, projectName: this.project.name, selectedSubProjectsIds: [], subProjectCount: 0 });
    if (this.updateListSubscription) {
      this.updateListSubscription.unsubscribe();
    }

  }
}

