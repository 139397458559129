import { Component, OnInit } from '@angular/core';
import { NavService, INavItem } from '../../service/common/nav.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  public showNavBar = false;
  private navItems: Array<INavItem> = [];
  constructor(private navService: NavService, private router: Router) {
    this.navService.$navItemsChanged.subscribe(navItems => {
      if (!navItems) {
        this.showNavBar = false;
      } else {
        this.showNavBar = true;
        this.navItems = navItems;
      }
    });
  }

  navigate(navItem: INavItem) {
    if (navItem.routeUrl) {
      this.router.navigateByUrl(navItem.routeUrl);
    }
  }

  ngOnInit() {
  }

}
