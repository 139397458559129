import { Component, EventEmitter, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-software-popup',
  templateUrl: './software-popup.component.html',
  styleUrls: ['./software-popup.component.scss']
})
export class SoftwarePopupComponent {
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();
  public isChecked: boolean;
  newFeatureCollapsed = false;
  constructor (public translate: TranslateService) {
    this.isChecked = false;
  }

  public onAccept() {
    this.notify.emit(this.isChecked);
  }

  public onCancel() {
    this.notify.emit(false);
  }
}
