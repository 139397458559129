import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import * as models from './../../models/models';
import * as _ from 'lodash';

@Component({
  selector: 'app-sorting',
  templateUrl: './sorting.component.html',
  styleUrls: ['./sorting.component.scss']
})

export class SortingComponent implements OnInit {
  @Input() sortOption: string;
  @Input() sortOptions: string[];
  @Output() value = new EventEmitter();
  sortinggroup: FormGroup;
  sortingcombo: FormControl;
  constructor() { }

  ngOnInit() {
    this.sortinggroup = new FormGroup({ sortingcombo: new FormControl() });
  }

  ngAfterViewInit() {
    this.sortinggroup.get('sortingcombo').valueChanges.subscribe(val => {
      this.value.emit(val);
    });
  }
  ngOnDestory() {
    // destroy
  }

}
