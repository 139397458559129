import { merge } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { AppConstants } from '../common/AppConstants';
import { SessionStorageService } from '../service/util/session-storage.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../service/common/auth.service';
import { environment } from 'environments/environment';
@Component({
    selector: 'app-registrationcallback',
    templateUrl: './registrationcallback.component.html',
    styleUrls: []
})
export class RegistrationCallbackComponent implements OnInit {
    private state: string;

    constructor(
        private auth: AuthService,
        private route: ActivatedRoute,
        private sessionStorageService: SessionStorageService) {
    }

    ngOnInit() {
      // check state
      this.retrieveState();
      const savedState = this.sessionStorageService.get(AppConstants.RegistrationStateStorageKey);
      if (!this.state || _.isEmpty(this.state) || this.state != savedState) {
        // state does not match
        console.log('Failed to verify state!');
      }

      // cleanup
      this.sessionStorageService.remove(AppConstants.RegistrationStateStorageKey);
      this.sessionStorageService.remove(AppConstants.RegistrationReturnUrl);

      // invalidate login
      this.auth.invalidateLogin();

      // redirect to login to retrieve new token with all customer data
      location.assign(this.auth.getAuthURL());
    }

    private retrieveState() {
        if (environment.localTesting) {
            this.state = 'test';
        }
        this.route.queryParams.pipe(merge(this.route.params)).subscribe((params: any) => {
            this.state = this.state || (!_.isEmpty(params['state']) ? params['state'] : null);
        });
    }
}
