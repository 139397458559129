import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpClient } from '../common/http-client';
import { ConfigService } from 'app/service/config.service';

@Injectable()
export class SurveyService {


  constructor(private httpClient: HttpClient, private configService: ConfigService) {
  }

  async getSurveyAvailability(applicationId, languageId, regionId): Promise<Boolean> {
    let params = new HttpParams();
    params = params.append('ApplicationId', applicationId);
    params = params.append('LanguageId', languageId);
    params = params.append('RegionId', regionId);

    return this.httpClient.get<boolean>(`${this.configService.config.APISurveyPathUrl}available`, params).toPromise();
  }
}
