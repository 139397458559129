import { Component, OnInit, Output, EventEmitter, ElementRef, AfterViewInit, ViewChild, Input } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit, AfterViewInit {
  @ViewChild('survey', { static: true }) survey: ElementRef;
  @Output() close: EventEmitter<boolean> = new EventEmitter();
  @Input() private surveyData: any;
  public surveyHtml: SafeHtml;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.surveyHtml = this.sanitizer.bypassSecurityTrustHtml('<custom-survey \
    applicationid="' + this.surveyData.applicationId + '" \
    applicationversion="' + this.surveyData.applicationVersion + '" \
    languageid="' + this.surveyData.languageId + '"  \
    regionid="' + this.surveyData.regionId + '" \
    region="' + this.surveyData.region + '" \
    username="' + this.surveyData.userName + '" \
    userid ="' + this.surveyData.userId + '" \
    accesstoken="' + this.surveyData.accessToken + '"></custom-survey>');
  }

  ngAfterViewInit() {
    const customSurvey = this.survey.nativeElement.querySelector('custom-survey');
    if (customSurvey) {
      customSurvey.addEventListener('closeSurvey', this.closeSurvey.bind(this))
    }
  }

  closeSurvey(result) {
    this.close.emit(result);
  }
}
