
import { forkJoin as observableForkJoin, Subject, Subscription } from 'rxjs';

import { mergeMap, debounceTime } from 'rxjs/operators';
import { UserService } from './../service/common/user.service';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from './../service/util/session-storage.service';
import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SharedService } from '../service/common/shared.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ProjectService } from './project.service';
import * as _ from 'lodash';
import { GenericValidator } from './../common/generic-validator';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { CustomValidators } from 'ng2-validation';
import { NavService, INavItem } from '../service/common/nav.service';
import { SubProjectService } from 'app/subproject/sub-project.service';
import { NationalAnnexSortingHelper } from '../common/utils';
import { SettingsComponent } from '../settings/settings.component';
import { SubProjectStatus } from 'app/subproject/sub-project-status';
import { ApplicationOptionsService } from 'app/service/common/application-options.service';
import { Options, PartialFactor, Project, SubProject } from 'app/models/models';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})

export class ProjectComponent implements OnInit, AfterViewInit, OnDestroy {
  public static UNITS_ID = 3;
  public static DEFAULT_NATIONAL_ANNEX = 7;
  public static DEFAULT_LOAD = 1;
  public static DEFAULT_DESIGN_CODE = 1;
  public static DEFAULT_LANGUAGE_CODE = 'en';
  public static DEFAULT_UNIT_SYSTEM = 1;
  public static DEFAULT_US_DEAD_LOAD_1 = 1.4;
  public static DEFAULT_US_DEAD_LOAD_2 = 1.2;
  public static DEFAULT_US_LIVE_LOAD_2 = 1.6;
  public static DEFAULT_US_PHI_ELASTIC = 0.9;
  @ViewChild('projectTabStrip', { static: true }) projectTabStrip: TabStripComponent;
  @ViewChild('generalGroup', { static: false }) generalGroupElement: ElementRef;
  @ViewChild('metricSystemGroup', { static: false }) metricSystemGroupElement: ElementRef;
  @ViewChild('usSystemGroup', { static: false }) usSystemGroupElement: ElementRef;
  @ViewChild('projectName', { static: false }) projectNameElement: ElementRef;
  @ViewChild('company', { static: false }) companyElement: ElementRef;
  @ViewChild('address1', { static: false }) address1Element: ElementRef;
  @ViewChild('address2', { static: false }) address2Element: ElementRef;
  @ViewChild('city', { static: false }) cityElement: ElementRef;
  @ViewChild('state', { static: false }) stateElement: ElementRef;
  @ViewChild('postalCode', { static: false }) postalCodeElement: ElementRef;
  @ViewChild('contractorName', { static: false }) contractorNameElement: ElementRef;
  @ViewChild('reference', { static: false }) referenceElement: ElementRef;
  @ViewChild('responsible', { static: false }) responsibleElement: ElementRef;
  @ViewChild('preparedBy', { static: false }) preparedByElement: ElementRef;
  @ViewChild('designCode', { static: false }) designCodeElement: ElementRef;
  @ViewChild('countryNationalAnnexes', { static: false }) nationalAnnexesElement: ElementRef;
  @ViewChild('loadUnit', { static: false }) codeElement: ElementRef;
  @ViewChild('lengthUnit', { static: false }) lengthUnitElement: ElementRef;
  @ViewChild('language', { static: false }) languageElement: ElementRef;
  @ViewChild('ulsGammaG', { static: false }) ulsGammaGElement: ElementRef;
  @ViewChild('ulsGammaQ', { static: false }) ulsGammaQElement: ElementRef;
  @ViewChild('sectionResistance', { static: false }) sectionResistanceElement: ElementRef;
  @ViewChild('elementResistance', { static: false }) elementResistanceElement: ElementRef;
  @ViewChild('steelProfile', { static: false }) steelProfileElement: ElementRef;
  @ViewChild('reinforcementSafety', { static: false }) reinforcementSafetyElement: ElementRef;
  @ViewChild('concrete', { static: false }) concreteElement: ElementRef;
  @ViewChild('connectorResistance', { static: false }) connectorResistanceElement: ElementRef;
  public projectForm: FormGroup;
  public generalGroup: FormGroup;
  public metricSystemGroup: FormGroup;
  public usSystemGroup: FormGroup;
  public asSystemGroup: FormGroup;
  public loadUnits: any[] = [];
  public lengthUnits: any[] = [];
  public languages: any[] = [];
  public nationalAnnexes: any[] = [];
  public loadCategories: any[] = [];
  public partialFactors: PartialFactor[] = [];
  public displayMessage: { [key: string]: string } = {};
  public designCodes: any[] = [];
  public unitSystems: any[] = [];
  public validationMessages: { [key: string]: { [key: string]: string } };
  public safetyFactor: any;
  public dropDownOptions: Options;
  public genericValidator: GenericValidator;
  public generalInformation: any;
  public projectId: number;
  public formErrors: any;
  public subProjectParam: any;
  public selectedTab: number;
  public language: string;
  public isNew: boolean;
  public showSaveOnDestroyDialog = false;
  public _saveOnLeaveSubject: Subject<boolean> = new Subject<boolean>();
  public $saveOnLeaveObs = this._saveOnLeaveSubject.asObservable();
  public project: Project | undefined;
  public canChangeUnitSystem = true;
  public canChangeCountry = true;
  public subprojects: SubProject[];
  public initialized = false;
  private subProjectSubscription: Subscription;

  constructor(private projectService: ProjectService,
    private subProjectService: SubProjectService,
    private sharedService: SharedService,
    private user: UserService,
    private sessionStorageService: SessionStorageService,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    private fb: FormBuilder,
    private router: Router,
    private navService: NavService,
    private applicationOptionsService: ApplicationOptionsService) {
    this.subProjectParam = {};

    this.projectId = this.activatedRoute.snapshot.params['id'] as number;
    this.subProjectParam.id = this.projectId;
    this.isNew = this.activatedRoute.snapshot.params['isNew'] === 'true';

    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['isNew']) {
        this.isNew = params['isNew'] === 'true';
      }
    });

    this.validationMessages = {
      projectName: {
        required: 'project-name-is-required'
      },
      preparedBy: {
        required: 'preparedby-is-required'
      }, ulsGammaG: {
        required: 'uls-combination-g-is-required'
      }, ulsGammaQ: {
        required: 'uls-combination-q-is-required'
      }, concrete: {
        required: 'concrete-is-required'
      }, reinforcementSafety: {
        required: 'reinforcement-safety-is-required'
      }, steelProfile: {
        required: 'steel-profile-is-required'
      }, connectorResistance: {
        required: 'connector-resistance-is-required'
      }, sectionResistance: {
        required: 'section-resistance-is-required'
      }, elementResistance: {
        required: 'element-resistance-is-required'
      }

    };
    this.genericValidator = new GenericValidator(this.validationMessages);
    this.initForm();
  }


  saveOnLeave(doSave = true, doLeave = true) {
    if (doSave) {
      this.saveProject(doLeave);
    } else {
      this.showSaveOnDestroyDialog = false;
      this._saveOnLeaveSubject.next(doLeave);
    }
  }

  onTabSelected(event) {
    this.selectedTab = event.index;
  }

  resetGeneralInformation(): void {
    this.generalInformation = {
      id: null,
      name: '',
      company: '',
      address1: '',
      contractor: '',
      comments: '',
      preparedBy: '',
      phone: '',
      email: ''
    };
  }

  private updateNav() {
    try {
      this.navService.setNav([<INavItem>{
        routeName: 'Home',
        routeUrl: '/projects'
      }, <INavItem>{
        routeName: this.generalGroup.get('projectName').value,
        routeUrl: undefined
      }
      ]);
    } catch (err) {

    }
  }

  backToProjects() {
    this.router.navigate(['/projects']);
  }

  onNationalAnnexChange(nationalAnnex) {
    this.projectForm.patchValue({
      metricSystemGroup: this.getPartialFactors(nationalAnnex.id)
    });
  }

  activateValueChanges() {
    this.generalGroup.get('projectName').valueChanges.pipe(debounceTime(200)).subscribe(() => {
      this.updateNav();
    });
    this.metricSystemGroup.get('countryNationalAnnexes').valueChanges.pipe(debounceTime(500)).subscribe(na => {
      this.onNationalAnnexChange(na);
    });
    this.projectForm.get('unitSystem').valueChanges.pipe(debounceTime(500)).subscribe(unitSystem => {
      this.onUnitSystemChange(unitSystem.id);
    });
    this.projectForm.get('designCode').valueChanges.pipe(debounceTime(500)).subscribe(designCode => {
      this.onDesignCodeChange(designCode.id);
    });
  }

  async ngOnInit() {
    this.sharedService.changeBreadCrumb('project');
    this.setDropDowns(this.applicationOptionsService.options);
    if (this.projectId) {
      this.sharedService.showLoader(true);
      this.subprojects = (await this.projectService.getsubProjects(this.projectId).toPromise()).subProjects;

      this.projectService.getProjectById(this.projectId).subscribe(
        (response: Project) => {
          this.project = this.initializeProject(response);
          this.onUnitSystemChange(this.project.unit);
          this.loadForm(this.project);
          this.subProjectSubscription = this.subProjectService.subProjectStatusChange.subscribe(subProjectStatusResponse => {
            const deletedSubprojectIds = subProjectStatusResponse.subProjects
              .filter(subproject => subproject.status == SubProjectStatus.deleted)
              .map(subproject => subproject.id);

            this.subprojects = this.subprojects.filter(x => !deletedSubprojectIds.includes(x.id))
            this.checkThatUserCanChangeUnitSystem();
            this.checkThatUserCanChangeCountry();
          });
          this.checkThatUserCanChangeCountry();
          this.checkThatUserCanChangeUnitSystem();
          this.subProjectParam = this.project;
          this.activateValueChanges();
          this.updateNav();
          if (this.isNew) {
            this.saveProject(false);
          }
        },
        (error: any) => {
          this.sharedService.showLoader(false);
          this.sharedService.popFailure('project-open-failure');
          console.log(error);
        },
        () => {
          this.initialized = true;
          this.sharedService.showLoader(false);
        }
      );
    } else {
      this.activateValueChanges();
    }
  }

  ngAfterViewInit() {

  }

  ngOnDestroy(): void {
    this.subProjectSubscription.unsubscribe();
    this.navService.setNav(null);
  }

  setDropDowns(options: Options): void {
    this.loadUnits = _.sortBy(options.loadUnits, [function (o) { return o.label; }]);
    this.lengthUnits = _.sortBy(options.lengthUnits, [function (o) { return o.label; }]);
    this.languages = _.sortBy(options.languages, [function (o) { return o.label; }]);
    // Sort the National Annexes with out None and add None to the end
    const filteredNationalAnnexes = options.nationalAnnexes.filter(item => item.labelKey !== 'none' && item.id !== 13 && item.id !== 15 && item.id !== 16);
    const noneNationalAnnex = options.nationalAnnexes.filter(item => item.labelKey === 'none')[0];
    const nationalAnnexes = _.sortBy(filteredNationalAnnexes, [function (o) { return o.label; }]);
    nationalAnnexes.push(noneNationalAnnex);
    this.nationalAnnexes = nationalAnnexes;
    this.partialFactors = options.partialFactors;
    this.designCodes = options.designCodes;
    this.unitSystems = options.unitSystems;
    this.dropDownOptions = options;
  }

  getPartialFactors(nationalAnnexId: number): any {
    this.safetyFactor = this.partialFactors.filter(item => item.nationalAnnexId === nationalAnnexId)[0];
    return {
      sectionResistance: this.safetyFactor.gammaM0,
      concrete: this.safetyFactor.gammaC,
      reinforcementSafety: this.safetyFactor.gammaS,
      ulsGammaG: this.safetyFactor.gammaG,
      ulsGammaQ: this.safetyFactor.gammaQ,
      steelProfile: this.safetyFactor.gammaP,
      connectorResistance: this.safetyFactor.gammaV,
      elementResistance: this.safetyFactor.gammaM1
    };
  }

  initializeProject(data: Project): Project {
    const settings = this.user.defaultSettings;
    const project: Project = {
      id: data.id,
      name: data.name,
      company: data.company,
      address1: data.address1,
      address2: data.address2,
      city: data.city,
      stateProvince: data.stateProvince,
      postalCode: data.postalCode,
      country: null,
      contractor: data.contractor,
      reference: data.reference,
      responsible: data.responsible,
      phone: data.phone,
      email: data.email,
      preparedBy: data.preparedBy,
      comments: data.comments,

      ulsGammaG: this.isNew ? settings.ulsGammaG : data.ulsGammaG,
      ulsGammaQ: this.isNew ? settings.ulsGammaQ : data.ulsGammaQ,
      designCode: this.isNew ? settings.designCode : data.designCode,
      countryNationalAnnexes: this.isNew ? settings.countryNationalAnnexes : data.countryNationalAnnexes,
      language: this.isNew ? settings.language : data.language,
      loadUnit: this.isNew ? settings.loadUnit : data.loadUnit,
      lengthUnit: this.isNew ? settings.lengthUnit : data.lengthUnit,
      structuralSteelSection: this.isNew ? settings.structuralSteelSection : data.structuralSteelSection,
      structuralSteelElement: this.isNew ? settings.structuralSteelElement : data.structuralSteelElement,
      concrete: this.isNew ? settings.concrete : data.concrete,
      connector: this.isNew ? settings.connector : data.connector,
      reinforcement: this.isNew ? settings.reinforcement : data.reinforcement,
      steelProfile: this.isNew ? settings.steelProfile : data.steelProfile,

      unit: this.isNew ? (settings.unit ? settings.unit : ProjectComponent.DEFAULT_UNIT_SYSTEM)
        : (data.unit ? data.unit : ProjectComponent.DEFAULT_UNIT_SYSTEM),
      usDeadLoad1: this.isNew ? (settings.usDeadLoad1 ? settings.usDeadLoad1 : ProjectComponent.DEFAULT_US_DEAD_LOAD_1)
        : (data.usDeadLoad1 ? data.usDeadLoad1 : ProjectComponent.DEFAULT_US_DEAD_LOAD_1),
      usDeadLoad2: this.isNew ? (settings.usDeadLoad2 ? settings.usDeadLoad2 : ProjectComponent.DEFAULT_US_DEAD_LOAD_2)
        : (data.usDeadLoad2 ? data.usDeadLoad2 : ProjectComponent.DEFAULT_US_DEAD_LOAD_2),
      usLiveLoad2: this.isNew ? (settings.usLiveLoad2 ? settings.usLiveLoad2 : ProjectComponent.DEFAULT_US_LIVE_LOAD_2)
        : (data.usLiveLoad2 ? data.usLiveLoad2 : ProjectComponent.DEFAULT_US_LIVE_LOAD_2),
      usPhiElastic: this.isNew ? (settings.usPhiElastic ? settings.usPhiElastic : ProjectComponent.DEFAULT_US_PHI_ELASTIC)
        : (data.usPhiElastic ? data.usPhiElastic : ProjectComponent.DEFAULT_US_PHI_ELASTIC),
      asPhiM: this.isNew ? settings.asPhiM : data.asPhiM,
      asPhiMb: this.isNew ? settings.asPhiMb : data.asPhiMb,
      asPhiVc: this.isNew ? settings.asPhiVc : data.asPhiVc,
      asPhiVm: this.isNew ? settings.asPhiVm : data.asPhiVm,
      asPhiS: this.isNew ? settings.asPhiS : data.asPhiS,
      asPhiC: this.isNew ? settings.asPhiC : data.asPhiC,
      asPhiVf: this.isNew ? settings.asPhiVf : data.asPhiVf,
      asPhiSC: this.isNew ? settings.asPhiSC : data.asPhiSC,
      asPhiSR: this.isNew ? settings.asPhiSR : data.asPhiSR
    };
    return project;
  }

  mapModel(): Project {
    const form = this.projectForm.getRawValue();
    const project: Project = {
      'id': this.projectId || 0,
      'name': form.generalGroup.projectName,
      'company': form.generalGroup.company,
      'address1': form.generalGroup.address1,
      'address2': form.generalGroup.address2,
      'city': form.generalGroup.city,
      'stateProvince': form.generalGroup.state,
      'postalCode': form.generalGroup.postalCode,
      'country': null,
      'contractor': form.generalGroup.contractorName,
      'phone': form.generalGroup.phone,
      'reference': form.generalGroup.reference,
      'responsible': form.generalGroup.responsible,
      'preparedBy': form.generalGroup.preparedBy,
      'email': form.generalGroup.email,
      'comments': form.generalGroup.comments,

      'designCode': form.designCode.id,
      'unit': form.unitSystem.id,
      'loadUnit': form.loadUnit.id,
      'lengthUnit': form.lengthUnit.id,

      'language': this.user.defaultSettings.language,
      'countryNationalAnnexes': form.metricSystemGroup.countryNationalAnnexes.id,
      'ulsGammaG': form.metricSystemGroup.ulsGammaG,
      'ulsGammaQ': form.metricSystemGroup.ulsGammaQ,
      'structuralSteelSection': form.metricSystemGroup.sectionResistance,
      'structuralSteelElement': form.metricSystemGroup.elementResistance,
      'concrete': form.metricSystemGroup.concrete,
      'connector': form.metricSystemGroup.connectorResistance,
      'reinforcement': form.metricSystemGroup.reinforcementSafety,
      'steelProfile': form.metricSystemGroup.steelProfile,

      'usDeadLoad1': form.usSystemGroup.usDeadLoad1,
      'usDeadLoad2': form.usSystemGroup.usDeadLoad2,
      'usLiveLoad2': form.usSystemGroup.usLiveLoad2,
      'usPhiElastic': form.metricSystemGroup.usPhiElastic,
      asPhiSR: form.asSystemGroup.asPhiSR,
      asPhiSC: form.asSystemGroup.asPhiSC,
      asPhiVf: form.asSystemGroup.asPhiVf,
      asPhiC: form.asSystemGroup.asPhiC,
      asPhiS: form.asSystemGroup.asPhiS,
      asPhiVm: form.asSystemGroup.asPhiVm,
      asPhiVc: form.asSystemGroup.asPhiVc,
      asPhiMb: form.asSystemGroup.asPhiMb,
      asPhiM: form.asSystemGroup.asPhiM
    };

    return project;
  }

  private recursiveMarkAsTouched = (formElement: any) => {
    if (formElement.controls) {
      _.values(formElement.controls).forEach((formControl) => {
        this.recursiveMarkAsTouched(formControl);
      });
    }
    if (formElement.markAsTouched) {
      formElement.markAsTouched();
    }
  }

  public saveProject(doLeave: boolean): void {
    this.recursiveMarkAsTouched(this.projectForm);
    window.scrollTo(0, 0);
    if (this.projectForm.valid) {
      const updatedModel = this.mapModel();
      this.sharedService.showLoader(true);
      if (this.projectId > 0) {
        this.projectService.updateProject(updatedModel).pipe(mergeMap((project) =>
          this.projectService.getsubProjects(project.id), (project, dataSub) => {
            return { project: project, dataSub: dataSub };
          })).subscribe(({ project, dataSub }) => {
            const request = [];
            if (dataSub && dataSub.subProjects && dataSub.subProjects.length > 0) {
              const options = this.applicationOptionsService.options;
              dataSub.subProjects.forEach((subProject: SubProject) => {
                const combinationFactor = options.combinationFactors.filter(
                  cf => cf.nationalAnnexId === updatedModel.countryNationalAnnexes && cf.loadCategoryId === subProject.loadCategory);
                if (combinationFactor.length > 0 && subProject.combinationFactor !== combinationFactor[0].value) {
                  subProject.combinationFactor = combinationFactor[0].value;
                  request.push(this.subProjectService.updateSubProject(subProject));
                }
              });
            }
            if (request.length > 0) {
              observableForkJoin(request).subscribe(() => this.onSaveComplete(project, doLeave));
            } else {
              this.onSaveComplete(project, doLeave);
            }
          },
            (error: any) => this.onSaveFailure(error, false),
            () => this.sharedService.showLoader(false));
      } else {
        this.projectService.createProject(updatedModel)
          .subscribe(
            (project) => this.onSaveComplete(project, doLeave),
            (error: any) => this.onSaveFailure(error, false),
            () => {
              this.sharedService.showLoader(false);
            }
          );
      }
    } else {
      // select the correct tab
      if (!(<any>this.projectForm.controls).generalGroup.valid) {
        this.projectTabStrip.selectTab(0);
        if (!(<any>this.projectForm.controls).generalGroup.controls.projectName.valid) {
          this.projectNameElement.nativeElement.focus();
        } else if (!(<any>this.projectForm.controls).generalGroup.controls.company.valid) {
          this.companyElement.nativeElement.focus();
        } else if (!(<any>this.projectForm.controls).generalGroup.controls.address1.valid) {
          this.address1Element.nativeElement.focus();
        } else if (!(<any>this.projectForm.controls).generalGroup.controls.address2.valid) {
          this.address2Element.nativeElement.focus();
        } else if (!(<any>this.projectForm.controls).generalGroup.controls.city.valid) {
          this.cityElement.nativeElement.focus();
        } else if (!(<any>this.projectForm.controls).generalGroup.controls.state.valid) {
          this.stateElement.nativeElement.focus();
        } else if (!(<any>this.projectForm.controls).generalGroup.controls.postalCode.valid) {
          this.postalCodeElement.nativeElement.focus();
        } else if (!(<any>this.projectForm.controls).generalGroup.controls.contractorName.valid) {
          this.contractorNameElement.nativeElement.focus();
        } else if (!(<any>this.projectForm.controls).generalGroup.controls.reference.valid) {
          this.referenceElement.nativeElement.focus();
        } else if (!(<any>this.projectForm.controls).metricSystemGroup.valid) {
          this.projectTabStrip.selectTab(1);
        } else if (!(<any>this.projectForm.controls).metricSystemGroup.controls.ulsGammaG.valid) {
          this.ulsGammaGElement.nativeElement.focus();
        } else if (!(<any>this.projectForm.controls).metricSystemGroup.controls.ulsGammaQ.valid) {
          this.ulsGammaQElement.nativeElement.focus();
        } else if (!(<any>this.projectForm.controls).metricSystemGroup.controls.sectionResistance.valid) {
          this.sectionResistanceElement.nativeElement.focus();
        } else if (!(<any>this.projectForm.controls).metricSystemGroup.controls.elementResistance.valid) {
          this.elementResistanceElement.nativeElement.focus();
        } else if (!(<any>this.projectForm.controls).metricSystemGroup.controls.steelProfile.valid) {
          this.steelProfileElement.nativeElement.focus();
        } else if (!(<any>this.projectForm.controls).metricSystemGroup.controls.reinforcementSafety.valid) {
          this.reinforcementSafetyElement.nativeElement.focus();
        } else if (!(<any>this.projectForm.controls).metricSystemGroup.controls.concrete.valid) {
          this.concreteElement.nativeElement.focus();
        } else if (!(<any>this.projectForm.controls).metricSystemGroup.controls.connectorResistance.valid) {
          this.connectorResistanceElement.nativeElement.focus();
        }
      }
    }
  }

  private onUnitSystemChange(unitSystemId) {
    this.setUnits(unitSystemId);
    this.projectForm.get('loadUnit').setValue(this.loadUnits[0]);
    this.projectForm.get('lengthUnit').setValue(this.lengthUnits[0]);
  }

  private onDesignCodeChange(designCode) {
    const selectedDesignCode = this.designCodes.find(dc => dc.id === designCode);
    this.projectForm.get('unitSystem').setValue(this.unitSystems.find(us => us.id === selectedDesignCode.defaultUnitSystem));
    this.nationalAnnexes = NationalAnnexSortingHelper.getSortedNationalAnnexes(this.dropDownOptions.nationalAnnexes, designCode);
    this.metricSystemGroup.get('countryNationalAnnexes').setValue(this.nationalAnnexes[0]);
    if (designCode === 1 || designCode === 3) {
      this.projectForm.get('unitSystem').disable({ emitEvent: false });
    } else {
      this.projectForm.get('unitSystem').enable({ emitEvent: false });
    }
    this.getULSCombFactor(designCode);
  }
  private getULSCombFactor(designCode: number) {
    if (designCode === 2) {
      this.usSystemGroup.get('usDeadLoad1').setValue(SettingsComponent.DEFAULT_US_DEAD_LOAD_1_AISC);
      this.usSystemGroup.get('usDeadLoad2').setValue(SettingsComponent.DEFAULT_US_DEAD_LOAD_2_AISC);
      this.usSystemGroup.get('usLiveLoad2').setValue(SettingsComponent.DEFAULT_US_LIVE_LOAD_2_AISC);
    }
    if (designCode === 3) {
      this.usSystemGroup.get('usDeadLoad1').setValue(SettingsComponent.DEFAULT_US_DEAD_LOAD_1_ASNZS);
      this.usSystemGroup.get('usDeadLoad2').setValue(SettingsComponent.DEFAULT_US_DEAD_LOAD_2_ASNZS);
      this.usSystemGroup.get('usLiveLoad2').setValue(SettingsComponent.DEFAULT_US_LIVE_LOAD_2_ASNZS);
    }
  }

  private setUnits(unitSystemId): void {
    this.lengthUnits = this.dropDownOptions.lengthUnits.filter(l => l.unitSystemId === unitSystemId && (l.id === 3 || l.id === 4));
    this.loadUnits = this.dropDownOptions.loadUnits.filter(l => l.unitSystemId === unitSystemId);
  }

  private onSaveComplete(project, doLeave: boolean): void {
    this.subProjectParam = project;
    this.projectForm.markAsPristine();
    this.sharedService.showLoader(false);
    this._saveOnLeaveSubject.next(doLeave);
    if (this.isNew) {
      this.sharedService.popSuccess('project-create-success');
    } else {
      this.sharedService.popSuccess('project-save-success');
    }
    if (!doLeave) {
      this.router.navigate(['/project', { id: project.id }]);
    }
  }


  checkThatUserCanChangeUnitSystem() {
    if (this.subprojects && this.subprojects.length > 0) {
      this.projectForm.get('unitSystem').disable({ emitEvent: false });
      this.projectForm.get('designCode').disable({ emitEvent: false });
      this.canChangeUnitSystem = false;
    }
    else {
      this.canChangeUnitSystem = true;
      this.projectForm.get('designCode').enable({ emitEvent: false });
      this.projectForm.get('unitSystem').enable({ emitEvent: false });
    }

    if (this.projectForm.value.designCode && (this.projectForm.value.designCode.id === 1 || this.projectForm.value.designCode.id === 3)) {
      this.projectForm.get('unitSystem').disable({ emitEvent: false });
    }
  }
  checkThatUserCanChangeCountry() {
    if (this.subprojects && this.subprojects.length > 0) {
      this.metricSystemGroup.get('countryNationalAnnexes').disable({ emitEvent: false });
      this.canChangeCountry = false;
    } else {
      this.metricSystemGroup.get('countryNationalAnnexes').enable({ emitEvent: false });
      this.canChangeCountry = true;
    }
  }

  private onSaveFailure(error, doLeave: boolean): void {
    this.sharedService.showLoader(false);
    this._saveOnLeaveSubject.next(doLeave);
    if (this.isNew) {
      this.sharedService.popFailure('project-create-failure');
    } else {
      this.sharedService.popFailure('project-save-failure');
    }
  }

  private initForm(): void {
    this.generalGroup = this.fb.group({
      projectName: [null, [Validators.required, CustomValidators.rangeLength([3, 50])]],
      company: [null, [CustomValidators.rangeLength([0, 50])]],
      address1: [null, [CustomValidators.rangeLength([0, 50])]],
      address2: [null, [CustomValidators.rangeLength([0, 50])]],
      city: [null, [CustomValidators.rangeLength([0, 50])]],
      state: [null, [CustomValidators.rangeLength([0, 10])]],
      postalCode: [null, [CustomValidators.rangeLength([0, 20])]],

      contractorName: [null, [CustomValidators.rangeLength([0, 50])]],
      reference: [null, [CustomValidators.rangeLength([0, 50])]],
      responsible: [null, [CustomValidators.rangeLength([0, 50])]],
      email: [null, [CustomValidators.email]],
      preparedBy: [null, [Validators.required, CustomValidators.rangeLength([3, 50])]],

      phone: [null, [Validators.pattern('^(\\+[1-9][0-9]*(\\([0-9]*\\)|-[0-9]*-))?[0]?[1-9][0-9\\- ]*$'),
      CustomValidators.rangeLength([10, 15])]],
      comments: [null]
    });

    this.metricSystemGroup = this.fb.group({
      countryNationalAnnexes: [null, [Validators.required]],
      sectionResistance: [null, [Validators.required, CustomValidators.range([1, 2])]],
      elementResistance: [null, [Validators.required, CustomValidators.range([1, 2])]],
      steelProfile: [null, [Validators.required, CustomValidators.range([1, 2])]],
      reinforcementSafety: [null, [Validators.required, CustomValidators.range([1, 2])]],
      concrete: [null, [Validators.required, CustomValidators.range([1, 2])]],
      connectorResistance: [null, [Validators.required, CustomValidators.range([1, 2])]],
      ulsGammaG: [null, [Validators.required, CustomValidators.range([1, 2])]],
      ulsGammaQ: [null, [Validators.required, CustomValidators.range([1, 2])]],
      usPhiElastic: [null, [Validators.required, Validators.min(0.5), Validators.max(1)]]
    });

    this.usSystemGroup = this.fb.group({
      usDeadLoad1: [null, [Validators.required, Validators.max(2), Validators.min(1)]],
      usDeadLoad2: [null, [Validators.required, Validators.max(2), Validators.min(1)]],
      usLiveLoad2: [null, [Validators.required, Validators.max(2), Validators.min(1)]]
    });

    this.asSystemGroup = this.fb.group({
      asPhiM: [null, [Validators.required, Validators.min(0.5), Validators.max(1)]],
      asPhiMb: [null, [Validators.required, Validators.min(0.5), Validators.max(1)]],
      asPhiVc: [null, [Validators.required, Validators.min(0.5), Validators.max(1)]],
      asPhiVm: [null, [Validators.required, Validators.min(0.5), Validators.max(1)]],
      asPhiS: [null, [Validators.required, Validators.min(0.5), Validators.max(1)]],
      asPhiC: [null, [Validators.required, Validators.min(0.5), Validators.max(1)]],
      asPhiVf: [null, [Validators.required, Validators.min(0.5), Validators.max(1)]],
      asPhiSC: [null, [Validators.required, Validators.min(0.5), Validators.max(1)]],
      asPhiSR: [null, [Validators.required, Validators.min(0.5), Validators.max(1)]]
    });

    this.projectForm = this.fb.group({
      generalGroup: this.generalGroup,
      designCode: [null, [Validators.required]],
      unitSystem: [null, [Validators.required]],
      lengthUnit: [null, [Validators.required]],
      loadUnit: [null, [Validators.required]],
      metricSystemGroup: this.metricSystemGroup,
      usSystemGroup: this.usSystemGroup,
      asSystemGroup: this.asSystemGroup
    });
  }

  loadForm(data: Project): void {
    const designCode = this.designCodes.filter(item => item.id === data.designCode)[0] || this.designCodes[0];
    this.nationalAnnexes = NationalAnnexSortingHelper.getSortedNationalAnnexes(this.dropDownOptions.nationalAnnexes, designCode.id);
    const nationalAnnex = this.nationalAnnexes.filter(item => item.id === data.countryNationalAnnexes)[0] || this.nationalAnnexes[1];
    const unitSystem = this.unitSystems.filter(item => item.id === data.unit)[0] || this.unitSystems[0];
    const lengthUnit = this.lengthUnits.filter(item => item.id === data.lengthUnit)[0] || this.lengthUnits[0];
    const loadUnit = this.loadUnits.filter(item => item.id === data.loadUnit)[0] || this.loadUnits[0];
    const settings = this.user.defaultSettings;

    this.generalGroup.get('projectName').setValue(data.name);
    this.generalGroup.get('company').setValue(data.company);
    this.generalGroup.get('address1').setValue(data.address1);
    this.generalGroup.get('address2').setValue(data.address2);
    this.generalGroup.get('city').setValue(data.city);
    this.generalGroup.get('state').setValue(data.stateProvince);
    this.generalGroup.get('postalCode').setValue(data.postalCode);
    this.generalGroup.get('contractorName').setValue(data.contractor);
    this.generalGroup.get('reference').setValue(data.reference);
    this.generalGroup.get('responsible').setValue(data.responsible);
    this.generalGroup.get('email').setValue(data.email);
    this.generalGroup.get('preparedBy').setValue(data.preparedBy);
    this.generalGroup.get('phone').setValue(data.phone);
    this.generalGroup.get('comments').setValue(data.comments);

    this.projectForm.get('designCode').setValue(designCode);
    this.metricSystemGroup.get('countryNationalAnnexes').setValue(nationalAnnex);
    this.metricSystemGroup.get('sectionResistance').setValue(data.structuralSteelSection || settings.structuralSteelSection);
    this.metricSystemGroup.get('elementResistance').setValue(data.structuralSteelElement || settings.structuralSteelElement);
    this.metricSystemGroup.get('steelProfile').setValue(data.steelProfile || settings.steelProfile);
    this.metricSystemGroup.get('reinforcementSafety').setValue(data.reinforcement || settings.reinforcement);
    this.metricSystemGroup.get('concrete').setValue(data.concrete || settings.concrete);
    this.metricSystemGroup.get('connectorResistance').setValue(data.connector || settings.connector);
    this.metricSystemGroup.get('ulsGammaG').setValue(data.ulsGammaG || settings.ulsGammaG);
    this.metricSystemGroup.get('ulsGammaQ').setValue(data.ulsGammaQ || settings.ulsGammaQ);
    this.metricSystemGroup.get('usPhiElastic').setValue(data.usPhiElastic || settings.usPhiElastic);

    this.usSystemGroup.get('usDeadLoad1').setValue(data.usDeadLoad1 || settings.usDeadLoad1);
    this.usSystemGroup.get('usDeadLoad2').setValue(data.usDeadLoad2 || settings.usDeadLoad2);
    this.usSystemGroup.get('usLiveLoad2').setValue(data.usLiveLoad2 || settings.usLiveLoad2);

    this.projectForm.get('unitSystem').setValue(unitSystem);
    this.projectForm.get('lengthUnit').setValue(lengthUnit);
    this.projectForm.get('loadUnit').setValue(loadUnit);
    this.asSystemGroup.get('asPhiM').setValue(data.asPhiM || SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiM);
    this.asSystemGroup.get('asPhiMb').setValue(data.asPhiMb || SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiMb);
    this.asSystemGroup.get('asPhiVc').setValue(data.asPhiVc || SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiVc);
    this.asSystemGroup.get('asPhiVm').setValue(data.asPhiVm || SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiVm);
    this.asSystemGroup.get('asPhiS').setValue(data.asPhiS || SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiS);
    this.asSystemGroup.get('asPhiC').setValue(data.asPhiC || SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiC);
    this.asSystemGroup.get('asPhiVf').setValue(data.asPhiVf || SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiVf);
    this.asSystemGroup.get('asPhiSC').setValue(data.asPhiSC || SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiSC);
    this.asSystemGroup.get('asPhiSR').setValue(data.asPhiSR || SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiSR);
    // fix: some legacy project has no design code value, assign default settings (for combination factors) for this project and save it
    if (!data.designCode) {
      this.saveProject(false);
    }
    this.checkThatUserCanChangeUnitSystem();
  }

  doCancel(e) {
    this.router.navigate(['/projects']);
  }
}
