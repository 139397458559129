
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/service/config.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from './../../common/http-client';
import * as models from './../../models/models';

@Injectable()
export class PersonalDataService {
  private _personalDataUrl = '';
  constructor(private configService: ConfigService, private _http: HttpClient) {
    if (environment.localTesting) {
     this._personalDataUrl = `${this.configService.config.serviceUrl}/users/personaldata`;
    } else {
     this._personalDataUrl = `${this.configService.config.serviceUrl}asws/profishvb/shear_connector_users/v1/personaldata`;
    }
  }

  public get(email: string): Observable<models.PersonalDataGrid> {
    let params = new HttpParams();
    params = params.append('email', email);
    return this._http
      .get(`${this._personalDataUrl}`, params);
  }

  public remove(email: string): Observable<object> {
    return this._http
      .delete(`${this._personalDataUrl}?email=${email}`);
  }
}
