import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent {
  @Input()
  public infoTitle: string;
  @Input()
  public infoText: string;
  public isTooltipVisible = false;

  constructor() { }
}
