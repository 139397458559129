
import { Directive, Injectable } from '@angular/core';
import { AbstractControl, NG_ASYNC_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProjectService } from './project.service';
@Injectable()
@Directive({
    selector: '[uniqueProjectName][ngModel]',
    providers: [
        {
            provide: NG_ASYNC_VALIDATORS, useExisting: ProjectNameValidator, multi: true
        }
    ]
})
export class ProjectNameValidator implements Validator {

    constructor(private service: ProjectService) {
    }

    validate(c: AbstractControl): Observable<ValidationErrors | null> {
        if (c.value.lenght < 3) {
            return of({ nameToShort: true });
        }
        return this.service.searchForProject(c.value).pipe(map((item: any) => {
            return (item.projects.findIndex(i => i.name === c.value) >= 0) ? { projectNameAlreadyTaken: true } : null;
        }));
    }
}
