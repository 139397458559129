import { EulaComponent } from './eula/eula.component';
import { UserAgreementAcceptGaurd } from './user-agreement-acceptguard.service';
import { LogoutComponent } from './logout.component';
import { Routes, RouterModule } from '@angular/router';
import { ProjectListComponent } from './project/project-list.component';
import { ProjectComponent } from './project/project.component';
import { SubProjectComponent } from './subproject/sub-project.component';
import { RouteGuard } from './service/common/routeguard.service';
import { OauthcallbackComponent } from './oauthcallback/oauthcallback.component';
import { ReportViewerComponent } from './report/report-viewer/report-viewer.component';
import { SubProjectSaveGuard } from './subproject/sub-project-saveguard.service';
import { ProjectSaveGuard } from './project/project-saveguard.service';
import { NgModule } from '@angular/core';
import { SettingsComponent } from './settings/settings.component';
import { RegistrationCallbackComponent } from './registrationcallback/registrationcallback.component';

const appRoutes: Routes = [
  { path: 'logout', component: LogoutComponent, data: { breadcrumb: 'logout' } },
  { path: 'oauth_callback', component: OauthcallbackComponent },
  { path: 'registration_callback', component: RegistrationCallbackComponent},
  { path: 'projects', component: ProjectListComponent , data: { breadcrumb: 'project-list' }, canActivate: [RouteGuard] },
  { path: 'eula', component: EulaComponent , data: { breadcrumb: 'project-list' }, canActivate: [RouteGuard] },
  { path: 'project', component: ProjectComponent , data: { breadcrumb: 'project' }, canActivate: [RouteGuard, UserAgreementAcceptGaurd], canDeactivate: [ProjectSaveGuard] },
  { path: 'subproject', component: SubProjectComponent , data: { breadcrumb: 'subproject' }, canActivate: [RouteGuard, UserAgreementAcceptGaurd], canDeactivate: [SubProjectSaveGuard] },
  { path: 'report', component: ReportViewerComponent , data: { breadcrumb: 'report' }, canActivate: [RouteGuard, UserAgreementAcceptGaurd] },
  { path: 'settings', component: SettingsComponent, canActivate: [RouteGuard] },
  { path: '**', redirectTo: 'projects', data: { breadcrumb: 'project-list' }, canActivate: [RouteGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
