import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataStateChangeEvent, GridComponent, PageChangeEvent, SelectionEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { ConfigService } from 'app/service/config.service';
import { environment } from 'environments/environment';
import * as FileSaver from 'file-saver';
import { Subject } from 'rxjs';
import { SharedService } from '../service/common/shared.service';
import { SearchService } from '../shared/search/search.service';
import * as models from './../models/models';
import { Project } from './../models/project';
import { AuthService } from './../service/common/auth.service';
import { UserService } from './../service/common/user.service';
import { WindowRefService } from './../service/common/window.ref.service';
import { AppStorageService } from './../service/util/app-storage.service';
import { SettingsService } from './../settings/settings.service';
import { ProjectStatus } from './project-status';
import * as _ from 'lodash';
import { ProjectService } from './project.service';

@Component({
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit {
  public sort: SortDescriptor[] = [];
  public gridView: ProjectService;
  @ViewChild(GridComponent, { static: true }) grid: GridComponent;
  public skip = 0;
  public pageSize = 10;
  public pageNumber = 1;
  public start = 0;
  private editDataItem: Project;
  private isNew: boolean;
  public shouldSortAscending = true;
  public searchString = '';
  public sortBy = '';
  private sortTitle = '';
  public sortOptions: string[];
  public sortOption: string;
  private language: string;
  public newProjectName: string;
  public newProjectPreparedBy: string;
  public showNewProjectDialog: boolean;
  public showDeleteDialog: boolean;
  public selectedProjects: Map<number, models.GridProject>;
  public selectProjectTrigger: number;
  public selectProjectEvent: SelectionEvent;
  public locale: string;
  public showImportDialog: boolean;
  public importProjectName: string;
  public importProjectFile: any;
  public showExport = false;
  public areItemsSelected: boolean;
  public disableCreateButton = false;
  public disableImportButton = false;
  public isImportedFileValid = false;
  public subprojectListShouldBeUpdated = new Subject<void>();

  constructor(
    private configService: ConfigService,
    private service: ProjectService,
    private sharedService: SharedService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private user: UserService,
    private settingsService: SettingsService,
    private windowRef: WindowRefService,
    private searchService: SearchService,
    private appStorage: AppStorageService,
    private auth: AuthService) {
    this.selectProjectTrigger = 0;
    this.sortBy = 'updatedOn';
    this.shouldSortAscending = false;
    this.gridView = service;
    this.showImportDialog = false;
    this.selectedProjects = new Map<number, models.GridProject>();
    this.areItemsSelected = false;
  }

  public ngOnInit(): void {
    this.sharedService.showLoader(true);
    this.settingsService.getSettings().subscribe(defaultSettings => {
      this.sharedService.showLoader(false);
      if (!defaultSettings) {
        this.router.navigate(['/settings']);
      } else {
        this.user.defaultSettings = defaultSettings;
        this.language = this.user.defaultSettings && this.user.defaultSettings.language ? this.user.defaultSettings.language :
          window.navigator.language;
        this.locale = window.navigator.language; // The date formats will be displayed in browser's locale always
        console.log('Project List Language = ', this.language);
      }
    });
    this.service.query({
      pageNumber: this.pageNumber, pageSize: this.pageSize, searchString: this.searchString,
      sortBy: this.sortBy, shouldSortAscending: this.shouldSortAscending
    });

    this.searchService.searchEvent.subscribe((searchText) => {
      this.search(searchText);
    });
  }

  public dataStateChange({ skip, take, sort }: DataStateChangeEvent): void {
    (skip > 0) ? this.pageNumber = (skip / take) + 1 : this.pageNumber = 1;
    if (sort && sort.length > 0) {
      this.sort = sort;
      this.sortBy = this.sort[0].field;
      this.shouldSortAscending = this.sort[0].dir === 'asc' ? true : false;
    }
    this.service.query({
      pageNumber: this.pageNumber, pageSize: this.pageSize,
      searchString: this.searchString, sortBy: this.sortBy, shouldSortAscending: this.shouldSortAscending
    });
  }
  public addHandler() {
    this.editDataItem = new Project();
    this.isNew = true;
  }

  public editHandler({ dataItem }) {
    this.editDataItem = dataItem;
    this.isNew = false;
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageNumber = (this.skip > 0) ? this.pageNumber = (this.skip / event.take) + 1 : 1;
    this.service.query({
      pageNumber: this.pageNumber, pageSize: this.pageSize,
      searchString: this.searchString, sortBy: this.sortBy, shouldSortAscending: this.shouldSortAscending
    });
  }

  public logout(): void {
    this.auth.logout();
    if (environment.localTesting) {
      this.router.navigate(['/logout']);
    }
  }

  public cancelHandler() {
    this.editDataItem = undefined;
  }

  public saveHandler(project: Project) {
    this.editDataItem = undefined;
  }

  onProjectSelect(dataItem) {

    this.router.navigate(['/project', { id: dataItem.id }]);
  }

  search(val: string): void {
    this.searchString = val;
    this.pageNumber = 1;
    this.skip = 0;
    this.service.query({
      pageNumber: this.pageNumber, pageSize: this.pageSize,
      searchString: this.searchString, sortBy: this.sortBy, shouldSortAscending: this.shouldSortAscending
    });
  }

  saveProejct() {
    if (this.disableCreateButton) {
      this.service.createProject(<models.Project>{
        name: this.newProjectName,
        preparedBy: this.newProjectPreparedBy
      }).subscribe(
        (project) => this.onSaveComplete(project),
        (error: any) => this.onSaveFailure(error)
      );
    }
  }

  addNewProject() {
    this.newProjectName = '';
    this.newProjectPreparedBy = this.user.authentication.userName;
    this.showNewProjectDialog = true;
  }

  private createNewProjectAndOpen() {
    if (this.newProjectName && this.newProjectName.length >= 3) {
      this.disableCreateButton = true;
      this.sharedService.showLoader(true);
      this.service.searchForProject(this.newProjectName).subscribe(response => {
        if (response.totalCount > 0) {
          response.projects.forEach(project => {
            if (project.name.toLowerCase() === this.newProjectName.toLowerCase()) {
              this.sharedService.popFailure('project-name-must-be-unique');
              this.disableCreateButton = false;
              this.sharedService.showLoader(false);
            }
          });
          this.saveProejct();
        } else {
          this.saveProejct();
        }
      }, () => { this.sharedService.showLoader(false); this.disableCreateButton = false; });

    }
  }

  private onSaveComplete(project, isNew = true): void {
    this.disableCreateButton = false;
    this.sharedService.showLoader(false);
    this.router.navigate(['/project', { id: project.id, isNew: isNew }]);
  }

  private onSaveFailure(error) {
    this.showNewProjectSaveFailedMessage();
  }

  private onProjectStatusChangeSuccess(status) {
    if (status === ProjectStatus.deleted) {
      this.areItemsSelected = false;
      this.selectedProjects.clear();
      this.sharedService.popSuccess('item-delete');
    }
    this.service.query({
      pageNumber: this.pageNumber, pageSize: this.pageSize, searchString: this.searchString,
      sortBy: this.sortBy, shouldSortAscending: this.shouldSortAscending
    });
    this.subprojectListShouldBeUpdated.next();
  }

  showNewProjectSaveFailedMessage = () => {
    this.sharedService.showLoader(false);
    this.sharedService.popFailure('project-create-failure');
  }

  public onDelete(selectedProjects: Map<number, models.GridProject>) {
    this.setStatus(selectedProjects, ProjectStatus.deleted);
  }

  public checkSelectedItems() {
    let areItemsSelected = false;
    this.selectedProjects.forEach(pr => {
      if (pr.selected) {
        areItemsSelected = true;
      } else {
        pr.subProjects.forEach(sp => {
          if (sp.selected) {
            areItemsSelected = true;
          }
        });
      }
    });
    this.areItemsSelected = areItemsSelected;
  }

  private setStatus(selectedProjects: Map<number, models.GridProject>, status: number): void {
    const projectIds: number[] = [];
    const subProjectIds: number[] = [];
    selectedProjects.forEach(pr => {
      if (pr.selected) {
        projectIds.push(pr.id);
      } else {
        pr.subProjects.forEach(sp => {
          if (sp.selected) {
            subProjectIds.push(sp.id);
          }
        })
      }
    })
    this.showDeleteDialog = false;
    if (projectIds.length > 0 || subProjectIds.length > 0) {
      this.service.setStatus(projectIds, subProjectIds, status)
        .subscribe(
          () => this.onProjectStatusChangeSuccess(status),
          (error: any) => this.sharedService.popFailure(error)
        );
    }
  }

  public deleteProject(): void {
    this.showDeleteDialog = true;
  }

  public onProjectSelected(event: SelectionEvent) {
    if (event.deselectedRows.length > 0) {
      event.deselectedRows.forEach(row => {
        if (this.selectedProjects.has(row.dataItem.id)) {
          this.selectedProjects.get(row.dataItem.id).selected = false;
        }
      });
    }
    if (event.selectedRows.length > 0) {
      event.selectedRows.forEach(row => {
        if (this.selectedProjects.has(row.dataItem.id)) {
          this.selectedProjects.get(row.dataItem.id).selected = true;
        } else {
          this.selectedProjects.set(row.dataItem.id, {
            id: row.dataItem.id,
            name: row.dataItem.name,
            selected: true,
            subProjects: new Map<number, models.GridSubProject>(),
          });
        }
      });
    }
    this.selectProjectEvent = event;
    this.checkSelectedItems();

  }

  public exportProject() {
    this.selectedProjects.forEach(pr => {
      const subProjectIds: number[] = [];
      pr.subProjects.forEach(sp => {
        if (sp.selected) {
          subProjectIds.push(sp.id);
        }
      })
      if (subProjectIds.length > 0 || pr.selected) {
        this.service.getProjectJson(pr.id, subProjectIds)
          .subscribe((data) => {
            const jsonData = new Blob([JSON.stringify(data)], { type: 'application/json' });
            const filename = pr.name + '-' + (new Date).toISOString() + '.json';
            FileSaver.saveAs(jsonData, filename);
          }
            , error => {
              console.log('Error export project');
            }, () => {
            });
      }
    })
  }

  public selectSubProject(event) {
    this.selectProjectEvent = null;
    if (!this.selectedProjects.has(event.projectId)) {
      this.selectedProjects.set(event.projectId, {
        id: event.projectId,
        name: event.projectName,
        selected: false,
        subProjects: new Map<number, models.GridSubProject>(),
      });
    }
    this.selectedProjects.get(event.projectId).subProjects.forEach(sp => sp.selected = false);
    event.selectedSubProjectsIds.forEach(id => {
      if (this.selectedProjects.get(event.projectId).subProjects.has(id)) {
        this.selectedProjects.get(event.projectId).subProjects.get(id).selected = true;
      } else {
        this.selectedProjects.get(event.projectId).subProjects.set(id, {
          selected: true,
          id: id,
        });
      }
    });
    if (event.selectedSubProjectsIds.length < event.subProjectCount) {
      this.selectedProjects.get(event.projectId).selected = false;
    } else {
      this.selectedProjects.get(event.projectId).selected = true;
    }
    this.checkSelectedItems();
  }

  public onProjectClick(project: any): void {
    this.router.navigate(['/project', { id: project.id }]);
  }

  openImportDialog() {
    this.importProjectName = '';
    this.isImportedFileValid = false;
    this.showImportDialog = true;
  }

  importFileChange(importFileEvent: any) {
    this.importProjectFile = importFileEvent.target.files[0] ? importFileEvent.target.files[0] : null;
    if (this.importProjectFile) {
      this.isImportedFileValid = this.importProjectFile.name.split('.').pop().toLowerCase() === 'json';
    } else {
      this.isImportedFileValid = false;
    }
  }

  public importProjectAndOpen() {
    this.disableImportButton = true;
    this.sharedService.showLoader(true);
    this.service.searchForProject(this.importProjectName).subscribe(response => {
      if (response.totalCount > 0) {
        response.projects.forEach(project => {
          if (project.name.toLowerCase() === this.importProjectName.toLowerCase()) {
            this.sharedService.popFailure('project-name-must-be-unique');
            this.disableImportButton = false;
            this.sharedService.showLoader(false);
          }
        });
        this.importProject();
      } else {
        this.importProject();
      }
    }, () => { this.sharedService.showLoader(false); this.disableImportButton = false; });
  }
  private importProject() {
    if (this.disableImportButton) {
      this.service.importProject(this.importProjectName, this.importProjectFile)
        .subscribe(
          (project) => this.onSaveComplete(project, false),
          (error: any) => {
            this.sharedService.showLoader(false);
            this.sharedService.popFailure('json-file-corrupted');
          });
    }
  }

  onFileDropped($event: FileList[]) {
    this.importProjectFile = $event[0];
    this.isImportedFileValid = this.importProjectFile.name.split('.').pop().toLowerCase() === 'json';
  }
}
