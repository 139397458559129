import { Component, Output, EventEmitter } from '@angular/core';
import { AppStorageService } from '../../service/util/app-storage.service';
import { AppConstants } from '../../common/AppConstants';

@Component({
  selector: 'app-cookie-notification',
  templateUrl: './cookie-notification.component.html',
  styleUrls: ['./cookie-notification.component.scss']
})
export class CookieNotificationComponent {
  @Output() viewPrivacyPolicy: EventEmitter<any> = new EventEmitter();
  public showInfo = false;

  constructor(private appStorageService: AppStorageService) {
    if (this.appStorageService.get(AppConstants.SHOW_COOKIE_INFO) !== true) {
      this.showInfo = true;
    }
  }

  public toggleDisplayStatus() {
    this.showInfo = !this.showInfo;
    this.appStorageService.set(AppConstants.SHOW_COOKIE_INFO, true);
  }

  public viewPolicy() {
    this.viewPrivacyPolicy.emit();
  }
}
