import { UserService } from './../../service/common/user.service';
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { TranslateService } from '@ngx-translate/core';

import { SharedService } from '../../service/common/shared.service';
import { UnitSystem } from 'app/models/models';

@Component({
  selector: 'point-loads',
  templateUrl: './pointloads.component.html',
  styleUrls: ['./pointloads.component.scss']
})
export class PointloadsComponent implements OnChanges, OnInit {
  public DEADLOAD = this.translate.instant('load-dead');
  public LIVELOAD = this.translate.instant('load-live');
  public POSITION: string;
  public LOAD0: string;
  public MAXPOINTLOADS = 10;
  @Input()
  public forceUnitTitle: string;
  @Input()
  public lengthUnitTitle: string;
  @Input()
  public livePointLoads: any[];
  @Input()
  public deadPointLoads: any[];
  @Input()
  public beamLength: number;
  public pointLoadsData: any[];
  public loadType = [];
  private editedRowIndex: number;
  public formGroup: FormGroup;
  private forceFactor: number;
  @Output()
  public pointLoadsUpdated: EventEmitter<any> = new EventEmitter<any>();
  constructor(private sharedService: SharedService,
    private user: UserService,
    private translate: TranslateService) {
    this.loadType = [this.DEADLOAD, this.LIVELOAD];
    this.pointLoadsData = [];
  }

  ngOnInit() {
    this.pointLoadsData = [];
    this.populatePointLoadsData(this.livePointLoads, this.deadPointLoads);
    const loadStr = ' (' + this.forceUnitTitle + ')';
    const lengthStr = ' (' + this.lengthUnitTitle + ')';
    this.POSITION = this.translate.instant('position') + lengthStr;
    this.LOAD0 = this.translate.instant('load0') + loadStr;
  }

  populatePointLoadsData = (livePointLoads: any[], deadPointLoads: any[]): void => {
    this.pointLoadsData = [];
    deadPointLoads.forEach(pl => this.pointLoadsData.push({
      'Load_Position': pl.location,
      'Load': pl.value,
      'Id': pl.id,
      'Type': this.DEADLOAD,
      'GridId': this.generateRowId(),
    }));
    livePointLoads.forEach(pl => this.pointLoadsData.push({
      'Load_Position': pl.location,
      'Load': pl.value,
      'Id': pl.id,
      'Type': this.LIVELOAD,
      'GridId': this.generateRowId(),
    }));
  }

  ngOnChanges(changes: SimpleChanges) {
    const livePointLoads: SimpleChange = changes.livePointLoads;
    const deadPointLoads: SimpleChange = changes.deadPointLoads;
    this.pointLoadsData = [];
    if (deadPointLoads) {
      deadPointLoads.currentValue.forEach(pl => this.pointLoadsData.push({
        'Load_Position': pl.location,
        'Load': pl.value,
        'Id': pl.id,
        'Type': this.DEADLOAD,
        'GridId': this.generateRowId(),
      }));
    }
    if (livePointLoads) {
      livePointLoads.currentValue.forEach(pl => this.pointLoadsData.push({
        'Load_Position': pl.location,
        'Load': pl.value,
        'Id': pl.id,
        'Type': this.LIVELOAD,
        'GridId': this.generateRowId(),
      }));
    }
  }

  public addHandler({ sender }) {
    this.closeEditor(sender);
    this.formGroup = new FormGroup({
      'Load_Position': new FormControl(0, Validators.compose([Validators.required,
      Validators.pattern(/^\d{1,5}(\.\d{1,4})?$/)])),
      'Load': new FormControl(0, Validators.compose([Validators.required,
      Validators.pattern(/^\d{1,6}(\.\d{1,4})?$/)])),
      'Id': new FormControl(),
      'Type': new FormControl(this.DEADLOAD, Validators.required),
      'GridId': new FormControl(this.generateRowId()),
    });
    sender.addRow(this.formGroup);
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);
    this.formGroup = new FormGroup({
      'Load_Position': new FormControl(dataItem.Load_Position,
        Validators.compose([Validators.required, Validators.pattern(/^\d{1,5}(\.\d{1,4})?$/)])),
      'Load': new FormControl(dataItem.Load,
        Validators.compose([Validators.required,
        Validators.pattern(/^\d{1,6}(\.\d{1,4})?$/)])),
      'Id': new FormControl(dataItem.Id),
      'Type': new FormControl(dataItem.Type, Validators.required),
      'GridId': new FormControl(dataItem.GridId),
    });
    sender.editRow(rowIndex, this.formGroup);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }) {
    const editedItem = formGroup.value;
    if (editedItem.Load_Position > this.beamLength) {
      this.sharedService.popFailure('check-location');
      return;
    }

    if (this.pointLoadsData.filter(l => (l.Type === editedItem.Type)
      && (l.GridId !== editedItem.GridId)).length >= this.MAXPOINTLOADS) {
      sender.closeRow(rowIndex);
      this.sharedService.popFailure('reached-limit-of-records');
      return;
    }

    this.deadPointLoads = [];
    this.livePointLoads = [];
    if (!isNew) {
      const index = this.pointLoadsData.findIndex(l => l.GridId === editedItem.GridId);
      this.pointLoadsData.splice(index, 1, editedItem);
    } else {
      this.pointLoadsData.push(editedItem);
    }
    for (const pointLoad of this.pointLoadsData) {
      if (pointLoad.Type === this.LIVELOAD) {
        this.livePointLoads.push({
          id: pointLoad.Id,
          location: pointLoad.Load_Position,
          value: pointLoad.Load
        });
      } else {
        this.deadPointLoads.push({
          id: pointLoad.Id,
          location: pointLoad.Load_Position,
          value: pointLoad.Load
        });
      }
    }
    this.pointLoadsUpdated.emit({
      livePointLoads: this.livePointLoads,
      deadPointLoads: this.deadPointLoads
    });
    sender.closeRow(rowIndex);
  }

  public removeHandler({ dataItem }) {
    const index = this.pointLoadsData.findIndex(p => p.GridId === dataItem.GridId);
    if (index >= 0) {
      this.pointLoadsData.splice(index, 1);
      this.updateLoadData();
    }
  }

  private updateLoadData() {
    this.deadPointLoads = [];
    this.livePointLoads = [];
    for (const pointLoad of this.pointLoadsData) {
      if (pointLoad.Type === this.LIVELOAD) {
        this.livePointLoads.push({
          id: pointLoad.Id,
          location: pointLoad.Load_Position,
          value: pointLoad.Load
        });
      } else {
        this.deadPointLoads.push({
          id: pointLoad.Id,
          location: pointLoad.Load_Position,
          value: pointLoad.Load
        });
      }
    }
    this.pointLoadsUpdated.emit({
      livePointLoads: this.livePointLoads,
      deadPointLoads: this.deadPointLoads
    });
  }

  private generateRowId() {
    return Math.floor(Math.random() * Date.now());
  }
}
