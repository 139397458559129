import { AppConstants } from './../../common/AppConstants';
import { UserAgreementAcceptGaurd } from './../../user-agreement-acceptguard.service';
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl, AbstractControl, ValidatorFn } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { TranslateService } from '@ngx-translate/core';
import * as models from './../../models/models';
import { SharedService } from '../../service/common/shared.service';

@Component({
  selector: 'line-loads',
  templateUrl: './lineloads.component.html',
  styleUrls: ['./lineloads.component.scss']
})
export class LineloadsComponent implements OnChanges, OnInit {
  public DEADLOAD = this.translate.instant('load-dead');
  public LIVELOAD = this.translate.instant('load-live');
  public ORIGIN: string;
  public WIDTH: string;
  public LOAD1: string;
  public LOAD2: string;
  public MAXLINELOADS = 3;
  @Input()
  public forceUnitTitle: string;
  @Input()
  public lengthUnitTitle: string;
  @Input()
  public liveLineLoads: any[];
  @Input()
  public deadLineLoads: any[];
  @Input()
  public beamLength: number;
  @Input()
  public designCode: number;
  public lineLoadsData: any[];
  public loadType = [];
  public formGroup: FormGroup;
  private editedRowIndex: number;
  @Output()
  public lineLoadsUpdated: EventEmitter<any> = new EventEmitter<any>();
  constructor(private sharedService: SharedService,
    private translate: TranslateService) {
    this.loadType = [this.DEADLOAD, this.LIVELOAD];
    this.populateLineLoadsData(this.liveLineLoads, this.deadLineLoads);
  }

  ngOnInit() {
    this.populateLineLoadsData(this.liveLineLoads, this.deadLineLoads);
    const loadStr = ' (' + this.forceUnitTitle + '/' + this.lengthUnitTitle + ')';
    const lengthStr = ' (' + this.lengthUnitTitle + ')';
    this.ORIGIN = this.translate.instant('origin') + lengthStr;
    this.WIDTH = this.translate.instant('length') + lengthStr;
    this.LOAD1 = this.translate.instant('load1') + loadStr;
    this.LOAD2 = this.translate.instant('load2') + loadStr;
  }

  public populateLineLoadsData = (liveLineLoads: any[], deadLineLoads: any[]): void => {
    this.lineLoadsData = [];
    if (deadLineLoads) {
      deadLineLoads.forEach(ll => this.lineLoadsData.push({
        'Origin': ll.originLoad.location,
        'Load_at_Origin': ll.originLoad.value,
        'Width': (ll.endLoad.location - ll.originLoad.location), // Width = end - origin
        'Load_at_End': ll.endLoad.value,
        'Id': ll.id,
        'OriginLoadId': ll.originLoad.id,
        'EndLoadId': ll.endLoad.id,
        'Type': this.DEADLOAD,
        'GridId': this.generateRowId(),
      }));
    }
    if (liveLineLoads) {
      liveLineLoads.forEach(ll => this.lineLoadsData.push({
        'Origin': ll.originLoad.location,
        'Load_at_Origin': ll.originLoad.value,
        'Width': (ll.endLoad.location - ll.originLoad.location), // Width = end - origin
        'Load_at_End': ll.endLoad.value,
        'Id': ll.id,
        'OriginLoadId': ll.originLoad.id,
        'EndLoadId': ll.endLoad.id,
        'Type': this.LIVELOAD,
        'GridId': this.generateRowId(),
      }));
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const liveLineLoads: SimpleChange = changes.liveLineLoads;
    const deadLineLoads: SimpleChange = changes.deadLineLoads;
    this.lineLoadsData = [];
    if (deadLineLoads) {
      deadLineLoads.currentValue.forEach(ll => this.lineLoadsData.push({
        'Origin': ll.originLoad.location,
        'Load_at_Origin': ll.originLoad.value,
        'Width': (ll.endLoad.location - ll.originLoad.location), // Width = end - origin
        'Load_at_End': ll.endLoad.value,
        'Id': ll.id,
        'OriginLoadId': ll.originLoad.id,
        'EndLoadId': ll.endLoad.id,
        'Type': this.DEADLOAD,
        'GridId': this.generateRowId(),
      }));
    }
    if (liveLineLoads) {
      liveLineLoads.currentValue.forEach(ll => this.lineLoadsData.push({
        'Origin': ll.originLoad.location,
        'Load_at_Origin': ll.originLoad.value,
        'Width': (ll.endLoad.location - ll.originLoad.location), // Width = end - origin
        'Load_at_End': ll.endLoad.value,
        'Id': ll.id,
        'OriginLoadId': ll.originLoad.id,
        'EndLoadId': ll.endLoad.id,
        'Type': this.LIVELOAD,
        'GridId': this.generateRowId(),
      }));
    }
  }

  public addHandler({ sender }) {
    this.closeEditor(sender);
    this.formGroup = new FormGroup({
      'Origin': new FormControl(0,
        Validators.compose([Validators.required,
        CustomValidators.min(0),
        Validators.pattern(/^\d{1,5}(\.\d{1,4})?$/)])),
      'Load_at_Origin': new FormControl(0,
        Validators.compose([Validators.required,
        CustomValidators.min(0),
        Validators.pattern(/^\d{1,6}(\.\d{1,4})?$/)])),
      'Width': new FormControl(0,
        Validators.compose([Validators.required,
        CustomValidators.min(0),
        Validators.pattern(/^\d{1,5}(\.\d{1,4})?$/)])),
      'Load_at_End': new FormControl(0,
        Validators.compose([Validators.required,
        CustomValidators.min(0),
        Validators.pattern(/^\d{1,6}(\.\d{1,4})?$/)])),
      'Id': new FormControl(),
      'OriginLoadId': new FormControl(),
      'EndLoadId': new FormControl(),
      'Type': new FormControl(this.DEADLOAD, Validators.required),
      'GridId': new FormControl(this.generateRowId()),
    });
    sender.addRow(this.formGroup);
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);
    this.formGroup = new FormGroup({
      'Origin': new FormControl(dataItem.Origin,
        Validators.compose([Validators.required, CustomValidators.min(0),
        Validators.pattern(/^\d{1,5}(\.\d{1,4})?$/)])),
      'Load_at_Origin': new FormControl(dataItem.Load_at_Origin,
        Validators.compose([Validators.required, CustomValidators.min(0),
        Validators.pattern(/^\d{1,6}(\.\d{1,4})?$/)])),
      'Width': new FormControl(dataItem.Width,
        Validators.compose([Validators.required, CustomValidators.min(0),
        Validators.pattern(/^\d{1,5}(\.\d{1,4})?$/)])),
      'Load_at_End': new FormControl(dataItem.Load_at_End,
        Validators.compose([Validators.required, CustomValidators.min(0),
        Validators.pattern(/^\d{1,6}(\.\d{1,4})?$/)])),
      'Id': new FormControl(dataItem.Id),
      'OriginLoadId': new FormControl(dataItem.OriginLoadId),
      'EndLoadId': new FormControl(dataItem.EndLoadId),
      'Type': new FormControl(dataItem.Type, Validators.required),
      'GridId': new FormControl(dataItem.GridId),
    });
    sender.editRow(rowIndex, this.formGroup);
  }


  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }) {
    const editedItem = formGroup.value;
    if (editedItem.Origin > this.beamLength || (editedItem.Origin + editedItem.Width) > this.beamLength) {
      this.sharedService.popFailure('check-location');
      return;
    }

    if (this.lineLoadsData.filter(l => (l.Type === editedItem.Type)
      && (l.GridId !== editedItem.GridId)).length >= this.MAXLINELOADS) {
      sender.closeRow(rowIndex);
      this.sharedService.popFailure('reached-limit-of-records');
      return;
    }

    if (!isNew) {
      const index = this.lineLoadsData.findIndex(l => l.GridId === editedItem.GridId);
      this.lineLoadsData.splice(index, 1, editedItem);
    } else {
      this.lineLoadsData.push(editedItem);
    }
    this.updateLoadData();
    sender.closeRow(rowIndex);
  }

  public removeHandler({ dataItem }) {
    const index = this.lineLoadsData.findIndex(p => p.GridId === dataItem.GridId);
    if (index >= 0) {
      this.lineLoadsData.splice(index, 1);
      this.updateLoadData();
    }
  }

  private updateLoadData() {
    this.deadLineLoads = [];
    this.liveLineLoads = [];
    for (const lineLoad of this.lineLoadsData) {
      if (lineLoad.Type === this.LIVELOAD) {
        this.liveLineLoads.push({
          'id': lineLoad.Id,
          'endLoad': {
            'id': lineLoad.EndLoadId,
            'location': lineLoad.Origin + lineLoad.Width,
            'value': lineLoad.Load_at_End
          },
          'originLoad': {
            'id': lineLoad.OriginLoadId,
            'location': lineLoad.Origin,
            'value': lineLoad.Load_at_Origin
          }
        });
      } else {
        this.deadLineLoads.push({
          'id': lineLoad.Id,
          'endLoad': {
            'id': lineLoad.EndLoadId,
            'location': lineLoad.Origin + lineLoad.Width,
            'value': lineLoad.Load_at_End
          },
          'originLoad': {
            'id': lineLoad.OriginLoadId,
            'location': lineLoad.Origin,
            'value': lineLoad.Load_at_Origin
          }
        });
      }
    }
    this.lineLoadsUpdated.emit({
      liveLineLoads: this.liveLineLoads,
      deadLineLoads: this.deadLineLoads
    });
  }

  private generateRowId() {
    return Math.floor(Math.random() * Date.now());
  }
}
