import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-aznzcombination-factor',
  templateUrl: './aznzcombination-factor.component.html',
  styleUrls: ['./aznzcombination-factor.component.scss']
})
export class AZNZCombinationFactorComponent implements OnInit {
  @Input() parentForm: FormGroup;

  constructor() { }

  ngOnInit() {
  }

}
