import { EventEmitter, Injectable, Output } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'environments/environment';
import * as _ from 'lodash';
import { AppConstants } from './../../common/AppConstants';
import { Authentication } from './../../models/authentication';
import { Settings, UnitSystem } from './../../models/models';
import { AppStorageService } from './../util/app-storage.service';
import { SessionStorageService } from './../util/session-storage.service';

@Injectable()
export class UserService {
  public authentication: Authentication;
  public defaultSettings: Settings;
  public unitSystem: UnitSystem;
  private isTest: boolean;
  private jwtHelper = new JwtHelperService();
  @Output()
  authenticated: EventEmitter<Authentication> = new EventEmitter<Authentication>();

  constructor(
    private appStorage: AppStorageService,
    private sessionStorageService: SessionStorageService,
    private appStorageService: AppStorageService) {
    this.isTest = environment.localTesting ? true : false;
  }

  public get isEulaAccepted(): boolean {
    return _.isObject(this.authentication) &&
      !_.isNull(this.authentication.eulaAcceptance) && !_.isEmpty(this.authentication.eulaAcceptance) &&
      this.authentication.eulaAcceptance === this.authentication.userId;
  }

  public get isAuthenticated() {
    let isAuthenticated: boolean = _.isObject(this.authentication) &&
      !_.isNull(this.authentication.accessToken) && !_.isEmpty(this.authentication.accessToken);
    if (isAuthenticated && !this.isTest) {
      isAuthenticated = !this.jwtHelper.isTokenExpired(this.authentication.accessToken);
    }
    return isAuthenticated;
  }

  public setAuthenticated(data: Authentication, remember?: boolean) {
    console.log('Set Authenticated, Data = ', data['userId'], data['userName'], data['company']);
    this.authentication = <Authentication>{
      accessToken: data.accessToken,
      license: data.license,
      userId: data.userId,
      userName: data.userName,
      company: data.company,
      customerOriginID: data.customerOriginID,
      eulaAcceptance: this.appStorageService.get<string>(AppConstants.HVB_EULA),
      countryOfResidence: data.countryOfResidence
    };
    // save to session
    this.sessionStorageService.set(AppConstants.authenticationStorageKey, this.authentication);
    if (remember) {
      this.appStorage.set(AppConstants.authenticationStorageKey, this.authentication);
    } else {
      this.appStorage.remove(AppConstants.authenticationStorageKey);
    }
    this.authenticated.emit(this.authentication);
  }

  public getAuthentication() {
    return this.authentication;
  }

  public invalidateAuthentication() {
    this.authentication = null;
    this.sessionStorageService.remove(AppConstants.authenticationStorageKey);
    this.appStorage.remove(AppConstants.authenticationStorageKey);
    if (this.appStorage.get(AppConstants.HVB_EULA)) {
      this.appStorage.set(AppConstants.HVB_PREVIOUS_USER, this.appStorage.get(AppConstants.HVB_EULA));
    }
    this.appStorage.remove(AppConstants.HVB_EULA);
  }

  public authenticatedFromStorage() {
    this.authentication = this.sessionStorageService.get<Authentication>(AppConstants.authenticationStorageKey) ||
      this.appStorage.get<Authentication>(AppConstants.authenticationStorageKey);

    if (!this.isAuthenticated) {
      this.invalidateAuthentication();
    } else {
      // trigger authenticated event
      this.authenticated.emit(this.authentication);
    }
  }

  public onAuthenticated(fn: (authentication: Authentication) => void) {
    // tslint:disable-next-line
    this.authenticated.subscribe((authentication) => { fn; });
  }

}
