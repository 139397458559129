import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { ButtonModule, CardModule, DropdownModule } from 'hilti-ui-library';
import { RouteGuard } from './../service/common/routeguard.service';
import { SharedModule } from './../shared/shared.module';
import { AZNZCombinationFactorComponent } from './aznzcombination-factor/aznzcombination-factor.component';
import { SettingsComponent } from './settings.component';
import { SettingsService } from './settings.service';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild([
      { path: 'settings', component: SettingsComponent, canActivate: [RouteGuard] }
    ]),
    ReactiveFormsModule,
    CardModule,
    DropdownModule,
    ButtonModule,
    DropDownListModule
  ],
  declarations: [
    SettingsComponent,
    AZNZCombinationFactorComponent
  ],
  exports: [
    AZNZCombinationFactorComponent
  ],
  providers: [
    SettingsService
  ]
})
export class SettingsModule { }
