import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-custom-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss']
})
export class CheckBoxComponent {
  @Input() id;
  @Input() isChecked: boolean;
  @Input() label;
  @Output() isCheckedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public checkBoxForm: FormGroup = new FormGroup({
    input: new FormControl(false, Validators.required)
  });

  public onChangeCheckBoxValue() {
    this.isChecked = !(this.isChecked);
    this.checkBoxForm.get('input').setValue(this.isChecked);
    this.isCheckedChange.emit(this.isChecked);
  }
}
