import { SharedService } from './../../service/common/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './../../service/common/user.service';
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'surface-loads',
  templateUrl: './surfaceloads.component.html',
  styleUrls: ['./surfaceloads.component.scss']
})
export class SurfaceloadsComponent implements OnChanges, OnInit {
  public LIVELOAD = 'live';
  public LOAD0: string;
  @Input()
  public forceUnitTitle: string;
  @Input()
  public lengthUnitTitle: string;
  @Input()
  public surfaceLoads: any;
  @Output()
  public surfaceLoadsUpdated: EventEmitter<any> = new EventEmitter<any>();
  public surfaceLoadsData: any[] = [];

  constructor(private formBuilder: FormBuilder,
    private user: UserService,
    private translate: TranslateService,
    private sharedService: SharedService) { }

  ngOnInit(): void {
    const loadStr = ' (' + this.forceUnitTitle + '/' + this.lengthUnitTitle + '2)';
    this.LOAD0 = this.translate.instant('load0') + loadStr;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.surfaceLoadsData = []
    this.surfaceLoadsData.push({ load: changes.surfaceLoads.currentValue });
  }

  public cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }) {
    if (!isEdited) {
      sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
    }
  }

  public cellCloseHandler(args: any) {
    const { formGroup } = args;
    this.surfaceLoadsUpdated.emit(formGroup.value.load);
  }

  public createFormGroup(dataItem: any): FormGroup {
    return this.formBuilder.group({
      'load': dataItem.load,
    });
  }
}
