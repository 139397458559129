import { Component, OnInit } from '@angular/core';
import { BrowserInformation } from '../models/models';
@Component({
  selector: 'app-non-supported-browser',
  templateUrl: './non-supported-browser.component.html',
  styleUrls: ['./non-supported-browser.component.scss']
})
export class NonSupportedBrowserComponent implements OnInit {
  isBrowserSupported = true;
  supportedBrowser = [
    { name: 'Chrome', minVersion: 47, image: 'assets/browsers/chrome.png' },
    { name: 'Firefox', minVersion: 43, image: 'assets/browsers/firefox.png' },
    { name: 'Microsoft Edge', minVersion: 20, image: 'assets/browsers/edge.png' }
    ];
  constructor() { }

  ngOnInit() {
    const currentBrowser = this.detectBrowser();
    this.validateBrowser(currentBrowser);
  }

  validateBrowser(currentBrowser: BrowserInformation) {
    const browser = this.supportedBrowser.find(x => x.name === currentBrowser.name);
    if (browser) {
      currentBrowser.version >= browser.minVersion ? this.isBrowserSupported = true : this.isBrowserSupported = false;
    } else {
      this.isBrowserSupported = false;
    }
  }

  detectBrowser(): BrowserInformation {
    const nAgt = navigator.userAgent;
    let browserName  = navigator.appName;
    let fullVersion  = '' + parseFloat(navigator.appVersion);
    let majorVersion = parseInt(navigator.appVersion, 10);
    let nameOffset, verOffset, ix;

    if ((verOffset = nAgt.indexOf('OPR/')) !== -1) {
      browserName = 'Opera';
      fullVersion = nAgt.substring(verOffset + 4);
    } else if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
      browserName = 'Opera';
      fullVersion = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf('Version')) !== -1) {
        fullVersion = nAgt.substring(verOffset + 8);
      }
    } else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
      browserName = 'Microsoft Internet Explorer';
      fullVersion = nAgt.substring(verOffset + 5);
    } else if ((verOffset = nAgt.indexOf('Edge')) !== -1) {
      browserName = 'Microsoft Edge';
      fullVersion = nAgt.substring(verOffset + 5);
    } else if ((verOffset = nAgt.indexOf('Edg')) !== -1) {
      browserName = 'Microsoft Edge';
      fullVersion = nAgt.substring(verOffset + 4);
    } else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
      browserName = 'Chrome';
      fullVersion = nAgt.substring(verOffset + 7);
    } else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
      browserName = 'Safari';
      fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf('Version')) !== -1) {
        fullVersion = nAgt.substring(verOffset + 8);
      }
    } else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
      browserName = 'Firefox';
      fullVersion = nAgt.substring(verOffset + 8);
    } else if ( (nameOffset = nAgt.lastIndexOf(' ') + 1) <
      (verOffset = nAgt.lastIndexOf('/')) )  {
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() === browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    if ((ix = fullVersion.indexOf(';')) !== -1) {
      fullVersion = fullVersion.substring(0, ix);
    }
    if ((ix = fullVersion.indexOf(' ')) !== -1) {
      fullVersion = fullVersion.substring(0, ix);
    }

    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion  = '' + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }
    return {
      name: browserName,
      version: majorVersion
    };
  }

}
