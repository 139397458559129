import * as models from '../models/models';

export class Guid {
  public static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}

export class NationalAnnexSortingHelper {
  public static getSortedNationalAnnexes(nationalAnnexes: models.NationalAnnex[], designCode: number) {
    return nationalAnnexes.filter(c => c.designCodeId === designCode).sort((a, b) => {
      if (a.label === 'None') {
        return -1;
      }
      if (b.label === 'None') {
        return -1;
      }
      if (a.label === b.label){
        return 0;
      }
      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
    });
  }
}
