/**
 * AppStorage interface
 **/
export interface IAppStorageService {
  get<T>(key: string): T;
  set(key: string, value: any): void;
  remove(key: string): void;
}

/**
 * Local storage dictionary
 **/
export class AppStorageService implements IAppStorageService {
  get<T>(key: string) {
    try {
      return <T>JSON.parse(localStorage.getItem(key));
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  set<T>(key: string, value: T) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }
}
