import { Directive } from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
  selector: '[appNotOnlyNumbersValidator][ngModel]',
  providers: [{provide: NG_VALIDATORS, useExisting: NotOnlyNumbersValidatorDirective, multi: true}]
})
export class NotOnlyNumbersValidatorDirective implements Validator {

  constructor() {
  }

  validate(c: AbstractControl): ValidationErrors | null {
    if (c.value === '') {
      return null;
    }
    const regex = new RegExp('(?!^\\d+$)^.+$');
    if (!regex.test(c.value)) {
      return { onlyNumbers: true};
    } else {
      return null;
    }
  }

}
