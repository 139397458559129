import { SharedComponent } from './shared.component';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToasterService, ToasterModule } from 'angular2-toaster';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { InputTrim } from 'app/shared/InputTrim';
import { InformationComponent } from './information/information.component';
import { CookieNotificationComponent } from './cookie-notification/cookie-notification.component';
import { CheckBoxComponent } from './check-box/check-box.component';
import { NotOnlyNumbersValidatorDirective } from './not-only-numbers-validator.directive';
import {HttpClient} from '@angular/common/http';
import { DragndropDirective } from './dragndrop.directive';
import {TranslationLoader} from './translation/translation-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    SharedComponent,
    InputTrim,
    InformationComponent,
    CookieNotificationComponent,
    CheckBoxComponent,
    NotOnlyNumbersValidatorDirective,
    DragndropDirective
  ],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule,
    ToasterModule,
    ReactiveFormsModule,
    FormsModule
  ],
    exports: [
        CommonModule,
        TranslateModule,
        ToasterModule,
        SharedComponent,
        InputTrim,
        InformationComponent,
        CookieNotificationComponent,
        CheckBoxComponent,
        NotOnlyNumbersValidatorDirective,
        DragndropDirective
    ],
  providers: [
    TranslateService,
    ToasterService
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule
    };
  }
}
