import { HttpClient } from './../../common/http-client';

import {empty as observableEmpty,  Observable } from 'rxjs';

import {mergeMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SettingsService } from '../../settings/settings.service';
import { ApplicationOptionsService } from './application-options.service';
import { Settings } from 'app/models/models';

@Injectable()
export class UrlService {

  constructor(
    private _http: HttpClient,
    private _settingsService: SettingsService,
    private applicationOptionsService: ApplicationOptionsService) {
  }

  public getCurrentUrls(): Observable<any> {
    return this._settingsService.getSettings().pipe(mergeMap(settings => {
      return this.getCurrentUrlsBySettings(settings);
    }));
  }

  public getCurrentUrlsBySettings(settings: Settings): Observable<any> {
    const options = this.applicationOptionsService.options;
    if (settings && options) {
      const currentCountry = options.nationalAnnexes.filter(c => c.id === settings.countryNationalAnnexes)[0].labelKey;
      return this._http.get(`./assets/url/${currentCountry.toLowerCase()}-url-setting.json`);
    } else {
      return observableEmpty();
    }
  }
}
