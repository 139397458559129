
import { Injectable } from '@angular/core';
import { HttpClient } from './../common/http-client';

import { ConfigService } from 'app/service/config.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Settings } from 'app/models/models';


@Injectable()
export class SettingsService {

    public settings: Settings;

    constructor(private configService: ConfigService, private _http: HttpClient) { }

    public getSettings(): Observable<Settings> {
        return this._http.get<Settings>(`${this.settingsUrl}settings`);
    }

    public saveSettings(settings: Settings): Observable<any> {
        return this._http.put(`${this.settingsUrl}settings`, settings);
    }

    private get settingsUrl(): string {
        const baseUrl = this.configService.config.serviceUrl;
        const path = environment.localTesting ? 'users/' : 'asws/profishvb/shear_connector_users/v1/';
        return `${baseUrl}${path}`;
    }
}
