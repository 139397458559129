import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';

import { ButtonModule, CheckboxModule } from 'hilti-ui-library';
import { SettingsService } from '../settings/settings.service';
import { SharedModule } from './../shared/shared.module';
import { PrivacyComponent } from './privacy.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    DialogModule,
    DropDownsModule,
    FormsModule,
    ButtonModule,
    CheckboxModule
  ],
  declarations: [
    PrivacyComponent
  ],
  providers: [
    SettingsService
  ],
  exports: [
    PrivacyComponent
  ]
})
export class PrivacyModule {};
