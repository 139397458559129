import { Injectable } from '@angular/core';
import { AppConstants } from '../../common/AppConstants';
import { StateGenerator } from '../../common/StateGenerator';
import { ConfigService } from '../config.service';
import { SessionStorageService } from '../util/session-storage.service';
import { RoutingService } from './routing.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})

export class LongregistrationService {
  listOfEnabledCountries = [
    'AE', // United Arab Emirates
    'AR', // Argentina
    'AT', // Austria
    'AU', // Australia
    'BE', // Belgium
    'BG', // Bulgaria
    'BH', // Bahrain
    'BR', // Brazil
    'BY', // Belarus
    'CA', // Canada
    'CH', // Switzerland
    'CL', // Chile
    'CZ', // Czech Republic
    'DE', // Germany
    'EE', // Estonia
    'ES', // Spain
    'FR', // France
    'GB', // United Kingdom
    'GR', // Greece
    'HR', // Croatia
    'HU', // Hungary
    'IL', // Israel
    'IT', // Italy
    'KZ', // Kazakhstan
    'KW', // Kuwait
    'LT', // Lithuania
    'LU', // Luxembourg
    'LV', // Latvia
    'MX', // Mexico
    'NL', // Netherlands
    'NZ', // New Zealand
    'OM', // Oman
    'PE', // Peru
    'PH', // Philippines
    'PL', // Poland
    'PT', // Portugal
    'QA', // Qatar
    'RO', // Romania
    'RS', // Serbia
    'SA', // Saudi Arabia
    'SG', // Singapore
    'SI', // Slovenia
    'SK', // Slovakia
    'TR', // Turkey
    'UA', // Ukraine
    'US', // United States
    'ZA'  // South Africa
  ];

  constructor(
    private userService: UserService,
    private sessionStorageService: SessionStorageService,
    private routingService: RoutingService,
    private configService: ConfigService
  ) { }

  public isLongRegistrationNeeded(): boolean {
    const doesCountrySupportLongRegistration = (countryCode: string) => {
      if (countryCode == null) {
        return false;
      }

      const supportedCountry = this.listOfEnabledCountries.filter(x => x == countryCode);

      if (supportedCountry && supportedCountry.length > 0) {
        return true;
      }

      return false;
    };

    const auth = this.userService.getAuthentication();
    if (!auth) {
      return false;
    }

    //
    // Check order is important here
    //
    if (this.isInternalHiltiUser(auth.userName) || // 1.) Check if user is internal - @hilti.com
      auth.customerOriginID || // 2.) Check if customer origin ID is already set (long registration already finished).
      !doesCountrySupportLongRegistration(auth.countryOfResidence) // 3.) Check if country isn't supported.
    ) {
      return false;
    }

    return true;
  }

  public async redirectToLongRegistration() {
    // Long registration needs Base64-encoded state!
    const stateEncoded = btoa(StateGenerator.generateState());
    this.sessionStorageService.set(AppConstants.RegistrationStateStorageKey, stateEncoded);

    // Save the current url for when we get back from the login page
    this.sessionStorageService.set(AppConstants.RegistrationReturnUrl, this.routingService.currentPath);

    const auth = this.userService.getAuthentication();

    const url = new URL(this.configService.config.longRegistrationUrl);
    // Country has to be upper case!
    url.searchParams.append('country', auth.countryOfResidence.toUpperCase());
    // Language only (without culture)!
    // We are not passing language (optional) because HOL cannot fallback to default language if our language is not supported (HOL throws error if country=DE and language=en)
    // url.searchParams.append('language', language);
    url.searchParams.append('redirect_url', this.routingService.buildRedirectUri(AppConstants.RegistrationCallback));
    url.searchParams.append('app_id', 'HSCD');
    url.searchParams.append('state', stateEncoded);
    url.searchParams.append('clientid', this.configService.config.clientId);

    window.location.href = url.toString();

    // return a promise that is never resolved or rejected since we are waiting for page navigation
    return new Promise<never>(() => { });
  }

  private isInternalHiltiUser(userName: string) {
    // that's how hilti cloud filters them
    return userName.indexOf('@hilti.com') > 0;
  }
}
