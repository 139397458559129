import { HttpClient } from './../common/http-client';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { ConfigService } from 'app/service/config.service';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { SetSubProjectStatusResponse } from './../models/subproject';
import * as models from './../models/models';

@Injectable()
export class SubProjectService extends BehaviorSubject<GridDataResult> {
  private _subProjectsUrl = '';
  public subProjectStatusChange: Subject<SetSubProjectStatusResponse>;
  constructor(private _http: HttpClient, private configService: ConfigService) {
    super(null);
    this.subProjectStatusChange = new Subject<SetSubProjectStatusResponse>();
    if (environment.localTesting) {
      this._subProjectsUrl = `${this.configService.config.serviceUrl}subprojects/`;
    } else {
      this._subProjectsUrl = `${this.configService.config.serviceUrl}asws/profishvb/shear_connector_subprojects/v1/`;
    }
  }

  public createSubProject(subProject: models.SubProject) {
    return this._http
      .post<models.SubProject>(`${this._subProjectsUrl}`, subProject);
  }

  public updateSubProject(subProject: models.SubProject) {
    return this._http
      .put(`${this._subProjectsUrl}${subProject.id}`, subProject);
  }

  public getSubProjectById(id: number): Observable<any> {
    return this._http
      .get(`${this._subProjectsUrl}${id}`);
  }

  public setStatus(ids: number[], status: number) {
    return this._http
      .put<SetSubProjectStatusResponse>(`${this._subProjectsUrl}setStatus`, {
        ids: ids,
        status: status,
      }).pipe(
        map(response => {
          this.subProjectStatusChange.next(response);
          return response;
        }));
  }
}
