import { Injectable } from '@angular/core';
import schema from '../../environments/environment.local.json';
import { environment } from 'environments/environment';
export type Environment = typeof schema;

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    private _config: Environment;

    constructor() { }

    async loadConfig(){
        if (environment.production) {
            const response = await fetch('/assets/config.json');
            const config = await response.json();
            this._config = config as Environment;
        }
        else {
            this._config =  require('../../environments/environment.local.json') as Environment;
        }

        return Promise.resolve();
    }

    public get config() {
        return this._config;
    }
}
