import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ToasterModule } from 'angular2-toaster';
import {
    ActionItemModule,
    ButtonModule,
    CheckboxModule,
    HeaderModule,
    LabelModule,
    MenuModule,
    TextInputModule
} from 'hilti-ui-library';
import { PersonalDataModule } from './admin/personal-data/personal-data.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { httpInterceptorProviders } from './common';
import { HttpClient } from './common/http-client';
import { SafePipe } from './common/safePipe';
import { EulaModule } from './eula/eula.module';
import { LegalComponent } from './legal/legal.component';
import { LogoutComponent } from './logout.component';
import { NonSupportedBrowserComponent } from './non-supported-browser/non-supported-browser.component';
import { OauthcallbackComponent } from './oauthcallback/oauthcallback.component';
import { PrivacyModule } from './privacy/privacy.module';
import { ProjectModule } from './project/project.module';
import { RegistrationCallbackComponent } from './registrationcallback/registrationcallback.component';
import { ReportModule } from './report/report.module';
import { RootComponent } from './root/root.component';
import { AuthService } from './service/common/auth.service';
import { NavService } from './service/common/nav.service';
import { RouteGuard } from './service/common/routeguard.service';
import { SharedService } from './service/common/shared.service';
import { UrlService } from './service/common/url.service';
import { UserService } from './service/common/user.service';
import { WindowRefService } from './service/common/window.ref.service';
import { ConfigService } from './service/config.service';
import { AppStorageService } from './service/util/app-storage.service';
import { SessionStorageService } from './service/util/session-storage.service';
import { SettingsModule } from './settings/settings.module';
import { NavigationComponent } from './shared/navigation/navigation.component';
import { SearchModule } from './shared/search/search.module';
import { SharedModule } from './shared/shared.module';
import { SoftwarePopupModule } from './software-popup/software-popup.module';
import { SurveyModule } from './survey/survey.module';
import { TipsComponent } from './tips/tips.component';
import { UserAgreementAcceptGaurd } from './user-agreement-acceptguard.service';
import { WhatsNewComponent } from './whats-new/whats-new.component';

@NgModule({
    declarations: [
        AppComponent,
        LogoutComponent,
        RootComponent,
        LegalComponent,
        OauthcallbackComponent,
        SafePipe,
        NavigationComponent,
        TipsComponent,
        WhatsNewComponent,
        NonSupportedBrowserComponent,
        RegistrationCallbackComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        HttpClientModule,
        AppRoutingModule,
        ProjectModule,
        SharedModule,
        SettingsModule,
        PrivacyModule,
        SearchModule,
        ToasterModule,
        ReportModule,
        DialogModule,
        NgbModule,
        DropDownsModule,
        EulaModule,
        PersonalDataModule,
        SurveyModule,
        SoftwarePopupModule,
        HeaderModule,
        TextInputModule,
        LabelModule,
        ButtonModule,
        MenuModule,
        ActionItemModule,
        CheckboxModule,
        ReactiveFormsModule
    ],
    providers: [
        httpInterceptorProviders,
        UserService,
        AuthService,
        SessionStorageService,
        AppStorageService,
        SharedService,
        UrlService,
        HttpClient,
        RouteGuard,
        UserAgreementAcceptGaurd,
        WindowRefService,
        NavService,
        ConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: (configService: ConfigService) => () => configService.loadConfig(),
            deps: [ConfigService],
            multi: true
        }
    ],
    exports: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
