import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class SearchService {
  public searchEvent: EventEmitter<any> = new EventEmitter();
  constructor() {

  }

}
