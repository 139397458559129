import { SettingsService } from '../settings/settings.service';
import { Component, EventEmitter, Output} from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { SharedService } from '../service/common/shared.service';
import * as models from './../models/models';
import { AppConstants } from '../common/AppConstants';
import { AppStorageService } from '../service/util/app-storage.service';
import { UserService } from '../service/common/user.service';
import { Authentication } from './../models/authentication';
import { SessionStorageService } from '../service/util/session-storage.service';
import { UrlService } from '../service/common/url.service';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'app/service/config.service';
import { ApplicationOptionsService } from 'app/service/common/application-options.service';

@Component({
  selector: 'privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent {
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();

  public policyMarketingHref = '';
  public policyMarketingTemplate = this.configService.config.profileUrl + AppConstants.PRIVACY_MARKETING_URL;

  public countries: Map<number, string> = new Map<number, string>();
  public policyForm: FormGroup = new FormGroup({
    tracking: new FormControl(false, Validators.required)
  });
  private settings: models.Settings;

  public selectedUrlsLanguage = {
    'name': 'english',
    'dsrurl': 'https://www.hilti.com',
    'ppurl': 'https://www.hilti.com',
    'marketingUrl': 'https://www.hilti.com',
    'value': 'en'
  };
  public selectedCountry: any = [{
    'name': 'english',
    'dsrurl': 'https://www.hilti.com',
    'ppurl': 'https://www.hilti.com',
    'marketingUrl': 'https://www.hilti.com',
    'value': 'en'
  }];
  public nationalAnnexes: any[];
  public uiLanguages: models.Language[];
  public selectedUiLanguage: models.Language;
  public currentUiLanguage: models.Language;

  constructor(
    private configService: ConfigService,
    private settingsService: SettingsService,
    public sharedService: SharedService,
    public appStorageService: AppStorageService,
    public sessionStorageService: SessionStorageService,
    public user: UserService,
    public translate: TranslateService,
    public urlService: UrlService,
    public applicationOptionsService: ApplicationOptionsService) {
      this.countries.set(1, 'EN');
      this.countries.set(2, 'FR');

      this.countries.set(3, 'ES');
      this.countries.set(4, 'PT');
      this.countries.set(5, 'BE');
      this.countries.set(6, 'LUX');
      this.countries.set(7, 'IT');
      this.countries.set(8, 'EN');
      this.countries.set(9, 'CZ');
      this.countries.set(10, 'BG');
      this.countries.set(11, 'RO');
      this.countries.set(12, 'EN');
      // this.countries.set(13, 'EE');
      this.countries.set(14, 'HU');
      // this.countries.set(15, 'LT');
      // this.countries.set(16, 'LV');
      this.countries.set(17, 'SK');
      this.countries.set(18, 'SI');
      this.countries.set(19, 'ZA');
      this.countries.set(20, 'SG');
      this.countries.set(21, 'TR');
      this.countries.set(22, 'AE');
      this.countries.set(23, 'QA');
      this.countries.set(24, 'SA');
      this.countries.set(25, 'AT');
      this.countries.set(26, 'DE');
      this.countries.set(27, 'NL');
      this.countries.set(28, 'PL');
      this.countries.set(29, 'CH');
      this.countries.set(30, 'US');
      this.countries.set(31, 'CA');
      this.countries.set(32, 'MX');
      this.countries.set(33, 'CO');
      this.countries.set(34, 'AR');
      this.countries.set(35, 'CL');
      this.countries.set(36, 'BR');
      this.countries.set(37, 'PE');
      this.countries.set(38, 'AU');
      this.countries.set(39, 'NZ');
      this.countries.set(47, 'GR');
      this.countries.set(48, 'RS');
      this.countries.set(49, 'HR');
      this.countries.set(50, 'IE');
      this.countries.set(51, 'SV');

      const authentication: Authentication = this.sessionStorageService.get<Authentication>(AppConstants.authenticationStorageKey);

      this.policyMarketingTemplate = this.policyMarketingTemplate.replace('${email}', authentication.userName);
      this.policyMarketingTemplate = this.policyMarketingTemplate.replace('${uid}', authentication.userId);
      const sessionStorageNationalAnnexes = this.applicationOptionsService.options.nationalAnnexes;
      // tslint:disable-next-line:max-line-length
      const filteredNationalAnnexes = sessionStorageNationalAnnexes
        .filter(item => item.labelKey !== 'none'
          && item.id !== 13
          && item.id !== 15
          && item.id !== 16
          && item.id !== 40
          && item.id !== 41
          && item.id !== 42
          && item.id !== 43
          && item.id !== 44
        );
      const noneNationalAnnex = sessionStorageNationalAnnexes.filter(item => item.labelKey === 'none')[0];
      const nationalAnnexes = _.sortBy(filteredNationalAnnexes, [function (o) { return o.label; }]);
      nationalAnnexes.push(noneNationalAnnex);
      this.nationalAnnexes = nationalAnnexes;
      const languagesOptions = this.applicationOptionsService.options.languages;
      this.uiLanguages = _.sortBy(languagesOptions, [function (o) { return o.label; }]);

      this.settingsService.getSettings().subscribe(settings => {
        this.settings = settings;
        this.policyForm.get('tracking').setValue(settings.trackingPolicy);
        this.selectedCountry = this.nationalAnnexes.find(c => c.id === settings.countryNationalAnnexes);
        this.selectedUiLanguage = this.uiLanguages.filter(item => (item.value === (settings && settings.language ? settings.language || 'en' : 'en')))[0];
        this.currentUiLanguage = this.selectedUiLanguage;
        this.setUrlsLanguage();
      });
    }

  acceptPrivacy() {
    this.settings.trackingPolicy = this.policyForm.value.tracking;
    this.settingsService.saveSettings(this.settings).subscribe(() => {
      this.appStorageService.set(AppConstants.HVB_PRIVACY_POLICY, this.user.authentication.userId);
      this.notify.emit(true);
    });
  }

  cancelPrivacy() {
    this.sharedService.changeLanguage(this.currentUiLanguage.value);
    this.notify.emit(false);
  }

  countryChange(event) {
    if (this.selectedCountry !== event) {
      this.selectedCountry = event;
      this.settings.countryNationalAnnexes = event.id;
      this.setUrlsLanguage();
    }
  }

  setUrlsLanguage() {
    this.urlService.getCurrentUrlsBySettings(this.settings).subscribe((urls) => {
      this.selectedUrlsLanguage = urls.languages.find(l => l.value === this.settings.language) || urls.languages[0];
      this.policyMarketingHref = this.policyMarketingTemplate.replace('${country}', this.countries.get(this.selectedCountry.id));
      this.policyMarketingHref = this.policyMarketingHref.replace('${lang}', this.selectedUrlsLanguage.value);
    });
  }

  uiLanguageChange(event) {
    this.selectedUiLanguage = event;
    this.settings.language = event.value;
    this.sharedService.changeLanguage(this.selectedUiLanguage.value);
    this.setUrlsLanguage();
  }
}
