
import { Subscription } from 'rxjs';

import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IToasterConfig, ToasterConfig } from 'angular2-toaster';
import { environment } from 'environments/environment';
import { pairwise } from 'rxjs/operators';
import { AppConstants } from './common/AppConstants';
import { Settings } from './models/models';
import { AuthService } from './service/common/auth.service';
import { ApplicationOptionsService } from './service/common/application-options.service';
import { INavItem, NavService } from './service/common/nav.service';
import { SharedService } from './service/common/shared.service';
import { UrlService } from './service/common/url.service';
import { UserService } from './service/common/user.service';
import { WindowRefService } from './service/common/window.ref.service';
import { ConfigService } from './service/config.service';
import { AppStorageService } from './service/util/app-storage.service';
import { SessionStorageService } from './service/util/session-storage.service';
import { SettingsService } from './settings/settings.service';
import { SearchService } from './shared/search/search.service';
import { SurveyService } from './survey/survey.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  private language: string;
  private langSubscription: Subscription;
  private settingsSubscription: Subscription;
  private langOptions: any[] = [];
  private referenceUrl: string;
  private techSpecUrlEc: string;
  private techSpecUrlAisc: string;
  private techSpecUrlAsnzs: string;
  public notifyOptions: any;
  public showLoader = false;
  public toasterConfig: ToasterConfig;
  private navToggle = false;
  public showEulaDialog = false;
  public showPrivacyDialog = false;
  public showTipsDialog = false;
  public showLegalNotices = false;
  public eulaReadOnly = false;
  public navItems: INavItem[] = [];
  public showSupportMenu = false;
  public showAdditionalMenu = false;
  public policyHref = '';
  public privacyAccepted = false;
  public showSurveyDialog = false;
  public isShowDialogAfterLogin = true;
  public languages: Map<string, number> = new Map<string, number>();
  public surveyData: any;
  public showWhatsNewDialog = false;
  public showSoftwarePopupDialog = false;
  private settings: Settings;

  constructor(
    public translate: TranslateService,
    public user: UserService,
    public auth: AuthService,
    public location: Location,
    public router: Router,
    public http: HttpClient,
    public route: ActivatedRoute,
    public sessionStorageService: SessionStorageService,
    public appStorageService: AppStorageService,
    public sharedService: SharedService,
    public applicationOptionsService: ApplicationOptionsService,
    public windowRef: WindowRefService,
    public activatedRoute: ActivatedRoute,
    public searchService: SearchService,
    public navService: NavService,
    public settingsService: SettingsService,
    public urlService: UrlService,
    public surveyService: SurveyService,
    private configService: ConfigService
  ) {
    this.appStorageService.set<String>(AppConstants.LOCALE, window.navigator.language);
    this.language = window.navigator.language; // Two character language like 'en'

    this.langOptions = this.applicationOptionsService.options ? this.applicationOptionsService.options.languages : [];
    const filtered = this.langOptions.filter(item => item.value === this.language);
    this.referenceUrl = filtered && filtered.length > 0 ? filtered[0].referenceUrl : AppConstants.DEFAULT_REFERENCE_URL;
    this.techSpecUrlEc = AppConstants.DEFAULT_TECH_SPEC_EC;
    this.techSpecUrlAisc = AppConstants.DEFAULT_TECH_SPEC_AISC;
    this.techSpecUrlAsnzs = AppConstants.DEFAULT_TECH_SPEC_ASNZS;
    this.sharedService.$loader.subscribe(visible => {
      this.showLoader = visible;
    });
    this.sharedService.$showEulaDialog.subscribe(visible => {
      this.showEulaDialog = visible;
    });
    this.sharedService.$showDialogAfterLogin.subscribe(visible => {
      if (visible) {
        const eulaId = this.appStorageService.get(AppConstants.HVB_EULA) || this.appStorageService.get(AppConstants.HVB_PREVIOUS_USER);
        if (eulaId && eulaId === this.user.authentication.userId) {
          this.goToSoftwarePopup();
        }
      }
    });
    this.navService.$navItemsChanged.subscribe(navItems => {
      this.navItems = navItems;
    });
    // Use pairwise to see the flow
    this.router.events.pipe(pairwise()).subscribe((event: any[]) => {
      // console.log('Pairwise subscribe on router = ', event);
      if (event[0] instanceof NavigationEnd && event[1] instanceof NavigationStart) {
        if (event[0].url !== '/oauth_callback') {
          appStorageService.set(AppConstants.ReturnUrl, event[0].url);
        }
      }
    });

    this.toasterConfig = new ToasterConfig(<IToasterConfig>{
      limit: 3,
      tapToDismiss: true,
      showCloseButton: true,
      newestOnTop: true,
      timeout: 3000,
      preventDuplicates: true,
      animation: 'slideUp',
      positionClass: 'toast-bottom-center'
    });

    this.languages.set('en', 1033);
    this.languages.set('fr', 1036);
    this.languages.set('it', 1040);
    this.languages.set('pt', 2070);
    this.languages.set('es', 1034);
    this.languages.set('nl', 1043);
    this.languages.set('de', 1031);
    this.languages.set('bg', 1026);
    this.languages.set('ro', 1048);
    this.languages.set('cs', 1029);
    this.languages.set('hu', 1038);
    this.languages.set('sk', 1051);
  }
  public get applicationVersion() {
    return this.configService.config.applicationVersion;
  }
  isSearchRoute() {
    if (this.router.url === '/projects' || this.router.url === '/settings') {
      return true;
    }
  }

  goToSettings() {
    this.router.navigate(['/settings']);
  }

  goToPrivacyPolicy() {
    this.showPrivacyDialog = true;
    this.isShowDialogAfterLogin = false;
  }

  goToTips() {
    this.showTipsDialog = true;
  }

  goToEndUserAgreement() {
    this.eulaReadOnly = true;
  }

  goToLegalNotices() {
    this.showLegalNotices = true;
  }

  goToReference() {
    window.open(this.referenceUrl);
  }

  goToTutorials() {
    this.urlService.getCurrentUrls().subscribe(url => {
      window.open(url.tutorual);
    });
  }

  goToXHvbSpecification() {
    this.urlService.getCurrentUrls().subscribe(url => {
      window.open(url.XHvbSpecification);
    });
  }

  goToProductPage() {
    this.urlService.getCurrentUrls().subscribe(url => {
      window.open(url.productPage);
    });
  }

  goToHelpdesk() {
    this.urlService.getCurrentUrls().subscribe(url => {
      window.open(url.helpdesk);
    });
  }

  goToTechnicalSpecEc() {
    window.open(this.techSpecUrlEc);
  }
  goToTechnicalSpecAisc() {
    window.open(this.techSpecUrlAisc);
  }

  goToTechnicalSpecAsnzs() {
    window.open(this.techSpecUrlAsnzs);
  }

  goToSoftware() {
    this.urlService.getCurrentUrls().subscribe(url => {
      window.open(url.software);
    });
  }

  goToDataSubProcessors() {
    window.open(AppConstants.SUBPROCESSOR_LIST_URL);
  }

  goToRoute(navItem: INavItem) {
    if (navItem.routeUrl) {
      this.router.navigateByUrl(navItem.routeUrl);
    }
  }

  goToWhatsNew() {
    this.showWhatsNewDialog = true;
  }

  goToSoftwarePopup() {
    if (this.settings
      && this.settings.countryNationalAnnexes === 7
      && this.settings.language === 'it'
      && this.settings.eTag !== AppConstants.E_TAG) {
      this.showSoftwarePopupDialog = true;
    }
  }

  cancelLegal() {
    this.showLegalNotices = false;
  }

  cancelPrivacy() {
    this.showPrivacyDialog = false;
  }

  cancelTips() {
    this.showTipsDialog = false;
  }

  closeSurvey(result) {
    this.showSurveyDialog = false;
    this.goToSoftwarePopup();
  }

  cancelWhatsNewDialog() {
    this.showWhatsNewDialog = false;
  }

  onCloseSoftwarePopupDialog(isNeverShowAgain) {
    this.showSoftwarePopupDialog = false;
    if (isNeverShowAgain) {
      this.settings.eTag = AppConstants.E_TAG;
      this.settingsService.saveSettings(this.settings).subscribe(settings => this.settings = settings);
    }
  }
  acceptEula() {
    this.user.authentication.eulaAcceptance = this.user.authentication.userId;
    this.appStorageService.set(AppConstants.HVB_EULA, this.user.authentication.userId);
    this.sharedService.showEulaDialog(false);
    this.showPrivacyDialog = true;
  }

  cancelEula() {
    if (this.eulaReadOnly) {
      this.eulaReadOnly = false;
      return;
    }
    if (this.user && this.user.authentication) {
      this.user.authentication.eulaAcceptance = null;
      this.appStorageService.set(AppConstants.HVB_EULA, this.user.authentication.eulaAcceptance);
    }
    this.logout();
    this.sharedService.showEulaDialog(false);
  }

  onClosePolicyDialogNotify(event) {
    this.showPrivacyDialog = false;
    if (this.isShowDialogAfterLogin) {
      this.goToSoftwarePopup();
    }
  }

  shouldEulaShown(): Boolean {
    const eulaId = this.appStorageService.get(AppConstants.HVB_EULA) || this.appStorageService.get(AppConstants.HVB_PREVIOUS_USER);
    return (this.showEulaDialog
      || this.eulaReadOnly
      || (this.user.authentication && eulaId && this.user.authentication.userId !== eulaId));
  }

  logout() {
    this.auth.logout();
    if (environment.localTesting) {
      this.router.navigate(['/logout']);
    }
  }

  ngOnInit() {
    this.eulaReadOnly = false;
    if (location.pathname === '/logout') {
      return;
    }
    this.user.authenticatedFromStorage();

    console.log('Language = ', this.language);

    this.langSubscription = this.sharedService.language$.subscribe(item => {
      this.language = item;
      this.translate.setDefaultLang(this.language);
    });

    this.settingsSubscription = this.sharedService.$settings.subscribe(settings => {
      if (settings) {
        this.urlService.getCurrentUrlsBySettings(settings).subscribe(urls => {
          if (urls) {
            const languageUrls = urls.languages.filter(p => p.value === this.language);
            this.policyHref = (languageUrls.length > 0 ? languageUrls[0] : urls.languages[0]).ppurl;
          }
        });
        this.settings = settings;
        this.sharedService.changeLanguage(settings.language);
      }
    });

    if (environment.localTesting) {
      this.appStorageService.set<string>(AppConstants.ReturnUrl, location.href);
      this.router.navigate(['/oauth_callback']);
    }
  }

  showSurvey() {
    const region = this.applicationOptionsService.options.nationalAnnexes
      .filter(c => c.id === this.settings.countryNationalAnnexes);

    this.surveyData = {
      applicationId: AppConstants.APPLICATION_ID,
      applicationVersion: this.configService.config.applicationVersion,
      languageId: this.languages.get(this.settings.language),
      regionId: this.settings.countryNationalAnnexes,
      region: region && region.length === 1 ? region[0].label : 'None'
    };

    if (environment.localTesting) {
      this.surveyData.userName = this.user.authentication.userName;
      this.surveyData.userId = this.user.authentication.userId;
    } else {
      this.surveyData.accessToken = this.user.authentication.accessToken;
    }

    this.surveyService.getSurveyAvailability(this.surveyData.applicationId, this.surveyData.languageId, this.surveyData.regionId)
      .then(isAvailable => {
        if (isAvailable) {
          this.showSurveyDialog = true;
        } else {
          this.goToSoftwarePopup();
        }
      });
  }

  toggleNav(onlyClose = false): void {
    if (onlyClose) {
      this.navToggle = false;
    } else {
      this.navToggle = !this.navToggle;
    }
  }
  navClass(): string {
    if (this.navToggle) {
      return '';
    } else {
      return 'collapse';
    }
  }
}
