
import { zip as observableZip, Subscription, Observable } from 'rxjs';

import { merge } from 'rxjs/operators';
import { SettingsService } from './../settings/settings.service';
import { AppStorageService } from '../service/util/app-storage.service';
import { WindowRefService } from '../service/common/window.ref.service';
import { ApplicationOptionsService } from '../service/common/application-options.service';
import { SharedService } from '../service/common/shared.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { AppConstants } from '../common/AppConstants';
import { SessionStorageService } from '../service/util/session-storage.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../service/common/auth.service';
import { UserService } from '../service/common/user.service';
import * as Models from '../models/models';
import { environment } from 'environments/environment';
@Component({
    selector: 'app-oauthcallback',
    templateUrl: './oauthcallback.component.html',
    styleUrls: ['./oauthcallback.component.scss']
})
export class OauthcallbackComponent implements OnInit {

    private code: string;
    private state: string;
    private language: string;
    private subscription: Subscription;

    constructor(
        private translate: TranslateService,
        private user: UserService,
        private auth: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private sessionStorageService: SessionStorageService,
        private appStorageService: AppStorageService,
        private sharedService: SharedService,
        private applicationOptionsService: ApplicationOptionsService,
        windowRef: WindowRefService,
        private settingsService: SettingsService) {
        this.appStorageService.set<String>(AppConstants.LOCALE, windowRef.nativeWindow.navigator.language);
        this.language = window.navigator.language; // Two character language like 'en-US'
    }

    async ngOnInit() {
        /**
         * if user is not authenticated and url does not includes oauth_callback then call the authorize URL
         * For development mimic oauth flow
         */
        this.subscription = this.sharedService.language$
            .subscribe(item => { this.language = item; this.translate.setDefaultLang(this.language); });
        this.retrieveCode();
        /**
         * If the user is not autheticated and location URL contains oauth_callback then call the token url
         * using the code param in the URL
         */
        if ((!this.user.isAuthenticated || environment.localTesting) && _.includes(location.href, AppConstants.OauthCallBack)) {
            if (this.code && !_.isEmpty(this.code) && this.state && !_.isEmpty(this.state)) {
                this.auth.getToken(this.code, this.state).subscribe(async data => {
                    this.auth.login(data);
                    this.onPostAuthentication().subscribe(async result => {
                        await this.applicationOptionsService.initialize();
                        this.updateLicenceData(result);
                        this.detectLanguage(result);
                        this.setInitiazationData(result);
                        this.sharedService.showDialogAfterLogin(true);
                        this.navigateToReturnUrl();
                    });
                });
            }
        }
        /**
         * If the user is authenticated then go to home page
         */
        if (this.user.isAuthenticated && _.includes(location.href, AppConstants.OauthCallBack)) {
            this.onPostAuthentication().subscribe(async result => {
                await this.applicationOptionsService.initialize();
                this.updateLicenceData(result);
                this.setInitiazationData(result);
                this.sharedService.showDialogAfterLogin(true);
                this.navigateToReturnUrl();
            });
        }
    }


    private detectLanguage(settings: Models.Settings | null): void {
        const auth = this.user.getAuthentication();
        if (!settings) {
            const locales = this.sharedService.getSupportedLocales();
            const defaultLang = 'en';
            locales.forEach((item, key) => {
                if (item[0].isoCode === auth.countryOfResidence) {
                    return this.sharedService.changeLanguage(item[0].value);
                }
            });
            return this.sharedService.changeLanguage(defaultLang);
        }
    }

    private retrieveCode() {
        if (environment.localTesting) {
            this.code = 'test';
            this.state = 'test';
        }
        this.route.queryParams.pipe(merge(this.route.params)).subscribe((params: any) => {
            this.code = this.code || (!_.isEmpty(params['code']) ? params['code'] : null);
            this.state = this.state || (!_.isEmpty(params['state']) ? params['state'] : null);
        });
    }

    private updateLicenceData(settings: Models.Settings) {
        if (settings == null) {
            return;
        }

        if (settings.name !== this.user.authentication.userName ||
            settings.customerId !== this.user.authentication.customerOriginID ||
            settings.region !== window.navigator.language
        ) {
            settings.name = this.user.authentication.userName;
            settings.customerId = this.user.authentication.customerOriginID;
            settings.region = window.navigator.language;
            this.settingsService.saveSettings(settings).subscribe();
        }
    }

    private setInitiazationData(result: Models.Settings) {
        this.user.defaultSettings = result;
        if (this.user.defaultSettings) {
            this.sharedService.changeSettings(this.user.defaultSettings);
        }
    }

    private getReturnUrl(): string {
        let returnUrl = <string>this.appStorageService.get(AppConstants.ReturnUrl);
        if (!this.user.isEulaAccepted) {
            returnUrl = '';
        }
        return returnUrl;
    }

    private navigateToReturnUrl(): void {
        const returnUrl = this.getReturnUrl();
        returnUrl !== null && !_.isEmpty(returnUrl) && returnUrl !== '/' && !_.includes(returnUrl, 'logout')
            && !_.includes(returnUrl, 'register')
            ? location.href = returnUrl : this.router.navigate(['/projects']);

    }

    private onPostAuthentication(): Observable<Models.Settings> {
        return this.settingsService.getSettings();
    }
}
