import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportViewerComponent } from './report-viewer/report-viewer.component';
import { RouterModule } from '@angular/router';
import { RouteGuard } from './../service/common/routeguard.service';
import { SharedModule } from './../shared/shared.module';
import { PdfViewerComponent} from 'ng2-pdf-viewer';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { FormsModule } from '@angular/forms';
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      { path: 'report', component: ReportViewerComponent, canActivate: [RouteGuard] }
    ]),
    SharedModule,
    FormsModule,
    InputsModule
  ],
  declarations: [ReportViewerComponent, PdfViewerComponent]
})
export class ReportModule { }
