
import { Observable, merge, fromEvent as observableFromEvent } from 'rxjs';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { CustomValidators } from 'ng2-validation';
import { debounceTime } from 'rxjs/operators';
import { AppConstants } from '../common/AppConstants';
import { NationalAnnexSortingHelper } from '../common/utils';
import { INavItem, NavService } from '../service/common/nav.service';
import { SharedService } from '../service/common/shared.service';
import { UserService } from './../service/common/user.service';
import { AppStorageService } from './../service/util/app-storage.service';
import { SettingsService } from './settings.service';
import { ApplicationOptionsService } from 'app/service/common/application-options.service';
import { Language, Option, Options, PartialFactor, Settings } from 'app/models/models';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})

export class SettingsComponent implements OnInit, OnDestroy, AfterViewInit {
  public static ATTR_READONLY = false;
  public static DEFAULT_LENGTH_UNIT_ID = 3; // meter
  public static DEFAULT_LOAD_UNIT_ID = 1; // kilo Newton
  public static DEFAULT_NATIONAL_ANNEX = 7;
  public static DEFAULT_LOAD_CATEGORY = 1;
  public static DEFAULT_LOAD = 1;
  public static DEFAULT_DESIGN_CODE = 1;
  public static DEFAULT_LANGUAGE_CODE = 'en';
  public static HOME = '/projects';
  public static DEFAULT_UNIT_SYSTEM = 1;
  public static DEFAULT_US_DEAD_LOAD_1_AISC = 1.4;
  public static DEFAULT_US_DEAD_LOAD_2_AISC = 1.2;
  public static DEFAULT_US_LIVE_LOAD_2_AISC = 1.6;
  public static DEFAULT_US_DEAD_LOAD_1_ASNZS = 1.35;
  public static DEFAULT_US_DEAD_LOAD_2_ASNZS = 1.2;
  public static DEFAULT_US_LIVE_LOAD_2_ASNZS = 1.5;
  public static DEFAULT_US_PHI_ELASTIC = 0.9;
  public static DEFAULT_LIVE_LOAD_REDUCTION = false;
  public static DEFAULT_COMB_FACTORS_ASNZS = {
    ASPhiM: 0.9,
    ASPhiMb: 0.9,
    ASPhiVc: 0.9,
    ASPhiVm: 0.9,
    ASPhiS: 0.9,
    ASPhiC: 0.8,
    ASPhiVf: 0.9,
    ASPhiSC: 0.8,
    ASPhiSR: 0.63
  };

  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  @ViewChild('combinationFactor', { static: false }) combinationFactorElement: ElementRef;
  @ViewChild('ulsGammaG', { static: false }) ulsGammaGElement: ElementRef;
  @ViewChild('ulsGammaQ', { static: false }) ulsGammaQElement: ElementRef;
  @ViewChild('sectionResistance', { static: false }) sectionResistanceElement: ElementRef;
  @ViewChild('elementResistance', { static: false }) elementResistanceElement: ElementRef;
  @ViewChild('steelProfile', { static: false }) steelProfileElement: ElementRef;
  @ViewChild('reinforcementSafety', { static: false }) reinforcementSafetyElement: ElementRef;
  @ViewChild('concrete', { static: false }) concreteElement: ElementRef;
  @ViewChild('connectorResistance', { static: false }) connectorResistanceElement: ElementRef;

  public settingsForm: FormGroup;

  public validationMessages: { [key: string]: { [key: string]: string } };
  public loadUnits: any[] = [];
  public lengthUnits: any[] = [];
  public languages: Language[] = [];
  public nationalAnnexes: any[] = [];
  public loadCategories: any[] = [];
  public designCodes: any[] = [];
  public combinationFactors: any[] = [];
  public partialFactors: PartialFactor[] = [];
  public unitSystems: any[] = [];
  public dropDownOptions: Options;
  public safetyFactor: any;
  public errorMessage: string;
  public returnUrl: string;

  // notification variables
  public saveSuccess = false;
  public saveError = false;
  public getError = false;

  constructor(
    private settingsService: SettingsService,
    private sharedService: SharedService,
    private appStorageService: AppStorageService,
    private fb: FormBuilder,
    private router: Router,
    private user: UserService,
    public translate: TranslateService,
    private navService: NavService,
    private applicationOptionsService: ApplicationOptionsService) {

    this.validationMessages = {
      ulsGammaG: {
        required: 'uls-combination-g-is-required'
      }, ulsGammaQ: {
        required: 'uls-combination-q-is-required'
      }, concrete: {
        required: 'concrete-is-required'
      }, reinforcement: {
        required: 'reinforcement-safety-is-required'
      }, combinationFactor: {
        required: 'combination-factor-is-required'
      }, steelProfile: {
        required: 'steel-profile-is-required'
      }, connector: {
        required: 'connector-resistance-is-required'
      }, structuralSteelSection: {
        required: 'section-resistance-is-required'
      }, structuralSteelElement: {
        required: 'element-resistance-is-required'
      }
    };
    this.returnUrl = <string>this.appStorageService.get(AppConstants.ReturnUrl);
    console.log('Reading return url from local storage = ', this.returnUrl);
  }

  ngOnInit() {
    this.sharedService.changeBreadCrumb('Settings');
    this.updateNav();
    this.dropDownOptions = this.applicationOptionsService.options;
    this.setDropDowns(this.dropDownOptions);
    this.initializeSettingForm();
    this.sharedService.showLoader(true);
    this.settingsService.getSettings().subscribe(defaultSettings => {
      if (defaultSettings) {
        this.sharedService.changeLanguage(defaultSettings.language);
        this.loadForm(defaultSettings);
      } else {
        this.loadEmptySettings();
        this.onSaveSettings();
      }
      this.activateValueChanges();
    },
      (error: any) => {
        this.loadEmptySettings();
        this.onGetFailure(error);
        console.log(error);
      }
      ,
      () => {
        this.sharedService.showLoader(false);
      });
  }

  private populateSettings(defaultSettings: any) {
    if (!defaultSettings) { return; }
    this.user.defaultSettings = defaultSettings;
    this.onLanguageChange(defaultSettings.language);
  }

  private getSettings() {
    this.sharedService.showLoader(true);
    this.settingsService.getSettings().subscribe(defaultSettings => {
      this.populateSettings(defaultSettings);
    }, (error: any) => {
      console.log('Error getting settings ', error);
    },
      () => {
        this.sharedService.showLoader(false);
      });
  }

  ngOnDestroy() {
    this.getSettings(); // Reload settings to make sure we have correct settings from the database that application can use.
  }

  private updateNav() {
    try {
      this.navService.setNav([<INavItem>{
        routeName: 'Home',
        routeUrl: SettingsComponent.HOME
      }
      ]);
    } catch (err) {

    }
  }

  getPartialFactors(nationalAnnexId: number): PartialFactor {
    return this.partialFactors.find(item => item.nationalAnnexId === nationalAnnexId);
  }

  ngAfterViewInit() {
    if (this.formInputElements) {
      const controls: Observable<any>[] = this.formInputElements
        .map((formControl: ElementRef) => observableFromEvent(formControl.nativeElement, 'change'));
    }
  }

  private setULSCombFactors(designCodeId: number) {
    this.settingsForm.get('usDeadLoad1').setValue(
      designCodeId === 2 ? SettingsComponent.DEFAULT_US_DEAD_LOAD_1_AISC : SettingsComponent.DEFAULT_US_DEAD_LOAD_1_ASNZS);
    this.settingsForm.get('usDeadLoad2').setValue(
      designCodeId === 2 ? SettingsComponent.DEFAULT_US_DEAD_LOAD_2_AISC : SettingsComponent.DEFAULT_US_DEAD_LOAD_2_ASNZS);
    this.settingsForm.get('usLiveLoad2').setValue(
      designCodeId === 2 ? SettingsComponent.DEFAULT_US_LIVE_LOAD_2_AISC : SettingsComponent.DEFAULT_US_LIVE_LOAD_2_ASNZS);
  }

  private setSafetyFactors(nationalAnnex: Option<number>, loadCategory: Option<number>) {
    const partialFactors = this.getPartialFactors(nationalAnnex.id);
    const combinationFactor = this.combinationFactors.
      filter(item => item.nationalAnnexId === nationalAnnex.id && item.loadCategoryId === loadCategory.id)[0].value;
    this.settingsForm.get('ulsGammaG').setValue(partialFactors.gammaG);
    this.settingsForm.get('ulsGammaQ').setValue(partialFactors.gammaQ);
    this.settingsForm.get('structuralSteelSection').setValue(partialFactors.gammaM0);
    this.settingsForm.get('structuralSteelElement').setValue(partialFactors.gammaM1);
    this.settingsForm.get('concrete').setValue(partialFactors.gammaC);
    this.settingsForm.get('connector').setValue(partialFactors.gammaV);
    this.settingsForm.get('reinforcement').setValue(partialFactors.gammaS);
    this.settingsForm.get('steelProfile').setValue(partialFactors.gammaP);
    this.settingsForm.get('combinationFactor').setValue(combinationFactor);
  }

  private setDropDowns(options: Options): void {
    this.setUnits(options, SettingsComponent.DEFAULT_UNIT_SYSTEM);
    this.languages = _.sortBy(options.languages, [function (o) { return o.label; }]);
    // Sort the National Annexes with out None and add None to the end
    const filteredNationalAnnexes = options.nationalAnnexes.filter(
      item =>
        item.labelKey !== 'none'
        && item.id !== 13
        && item.id !== 15
        && item.id !== 16);
    const noneNationalAnnex = options.nationalAnnexes.filter(item => item.labelKey === 'none')[0];
    const nationalAnnexes = _.sortBy(filteredNationalAnnexes, [function (o) { return o.label; }]);
    nationalAnnexes.push(noneNationalAnnex);
    this.nationalAnnexes = NationalAnnexSortingHelper.getSortedNationalAnnexes(this.dropDownOptions.nationalAnnexes,
      SettingsComponent.DEFAULT_DESIGN_CODE);
    const filteredLoadCategories = options.loadCategories.filter(c => c.designCodeId === SettingsComponent.DEFAULT_DESIGN_CODE);
    this.loadCategories = _.sortBy(filteredLoadCategories, [function (o) { return o.id; }]);
    this.partialFactors = options.partialFactors;
    this.designCodes = options.designCodes;
    this.unitSystems = options.unitSystems;
    this.combinationFactors = options.combinationFactors;
  }

  private setUnits(options, unitSystemId): void {
    this.lengthUnits = options.lengthUnits.filter(l => l.unitSystemId === unitSystemId && (l.id === 3 || l.id === 4));
    this.loadUnits = options.loadUnits.filter(l => l.unitSystemId === unitSystemId);
  }

  private loadEmptySettings(): void {
    const partialFactors = this.getPartialFactors(SettingsComponent.DEFAULT_NATIONAL_ANNEX);
    const combinationFactor = this.combinationFactors.filter(item =>
      item.nationalAnnexId === SettingsComponent.DEFAULT_NATIONAL_ANNEX
      && item.loadCategoryId === SettingsComponent.DEFAULT_LOAD_CATEGORY)[0].value;
    this.loadForm(<Settings>{
      'designCode': SettingsComponent.DEFAULT_DESIGN_CODE,
      'countryNationalAnnexes': SettingsComponent.DEFAULT_NATIONAL_ANNEX,
      'language': SettingsComponent.DEFAULT_LANGUAGE_CODE,
      'loadUnit': SettingsComponent.DEFAULT_LOAD_UNIT_ID,
      'lengthUnit': SettingsComponent.DEFAULT_LENGTH_UNIT_ID,
      'loadCategory': SettingsComponent.DEFAULT_LOAD,
      'ulsGammaG': partialFactors.gammaG,
      'ulsGammaQ': partialFactors.gammaQ,
      'structuralSteelSection': partialFactors.gammaM0,
      'structuralSteelElement': partialFactors.gammaM1,
      'concrete': partialFactors.gammaC,
      'connector': partialFactors.gammaV,
      'reinforcement': partialFactors.gammaS,
      'steelProfile': partialFactors.gammaP,
      'combinationFactor': combinationFactor,
      'unit': SettingsComponent.DEFAULT_UNIT_SYSTEM,
      'usDeadLoad1': SettingsComponent.DEFAULT_US_DEAD_LOAD_1_AISC,
      'usDeadLoad2': SettingsComponent.DEFAULT_US_DEAD_LOAD_2_AISC,
      'usLiveLoad2': SettingsComponent.DEFAULT_US_LIVE_LOAD_2_AISC,
      'usPhiElastic': SettingsComponent.DEFAULT_US_PHI_ELASTIC,
      'liveLoadReduction': SettingsComponent.DEFAULT_LIVE_LOAD_REDUCTION,
      'asPhiM': SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiM,
      'asPhiMb': SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiMb,
      'asPhiVc': SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiVc,
      'asPhiVm': SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiVm,
      'asPhiS': SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiS,
      'asPhiC': SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiS,
      'asPhiVf': SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiVf,
      'asPhiSC': SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiSC,
      'asPhiSR': SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiSR
    });
    this.settingsForm.markAsDirty();
  }


  private loadForm(settings: Settings): void {

    const lang = this.languages.filter(item => (item.value === (settings && settings.language ? settings.language || 'en' : 'en')))[0];
    const designCode = this.designCodes.filter(item => (item.id === (settings && settings.designCode ? settings.designCode || 1 : 1)))[0];
    this.nationalAnnexes = NationalAnnexSortingHelper.getSortedNationalAnnexes(this.dropDownOptions.nationalAnnexes,
      designCode.id);
    const nationalAnnexId = settings && settings.countryNationalAnnexes ?
      settings.countryNationalAnnexes : SettingsComponent.DEFAULT_NATIONAL_ANNEX;
    const nationalAnnex = this.nationalAnnexes.filter(item => item.id === nationalAnnexId)[0];
    const unitSystem = this.unitSystems.filter(item => (item.id === (settings && settings.unit ? settings.unit || 1 : 1)))[0];
    this.setUnits(this.dropDownOptions, unitSystem.id);
    const loadUnit = this.loadUnits.filter(item => (item.id === (settings && settings.loadUnit ? settings.loadUnit || 1 : 1)))[0];
    const lengthUnit = this.lengthUnits.filter(item => (item.id === (settings && settings.lengthUnit ? settings.lengthUnit || 1 : 1)))[0];
    this.loadCategories = this.dropDownOptions.loadCategories.filter(c => c.designCodeId === designCode.id);
    // tslint:disable-next-line:max-line-length
    const loadCategory = this.loadCategories.filter(item => (item.id === (settings && settings.loadCategory ? settings.loadCategory || 1 : 1)))[0];
    const combinationFactor = settings && settings.combinationFactor ? settings.combinationFactor : this.combinationFactors.filter(item => item.nationalAnnexId === nationalAnnex.id && item.loadCategoryId === loadCategory.id)[0].value;
    const partialFactors = this.getPartialFactors(nationalAnnex.id);

    // this.partialFactors
    if (this.settingsForm) {
      this.settingsForm.get('designCode').setValue(designCode);
      this.settingsForm.get('nationalAnnexes').setValue(nationalAnnex);
      this.settingsForm.get('language').setValue(lang);
      this.settingsForm.get('loadUnit').setValue(loadUnit);
      this.settingsForm.get('lengthUnit').setValue(lengthUnit);
      this.settingsForm.get('loadCategory').setValue(loadCategory);
      this.settingsForm.get('ulsGammaG').setValue(settings.ulsGammaG || partialFactors.gammaG);
      this.settingsForm.get('ulsGammaQ').setValue(settings.ulsGammaQ || partialFactors.gammaQ);
      this.settingsForm.get('structuralSteelSection').setValue(settings.structuralSteelSection || partialFactors.gammaM0);
      this.settingsForm.get('structuralSteelElement').setValue(settings.structuralSteelElement || partialFactors.gammaM1);
      this.settingsForm.get('concrete').setValue(settings.concrete || partialFactors.gammaC);
      this.settingsForm.get('connector').setValue(settings.connector || partialFactors.gammaV);
      this.settingsForm.get('reinforcement').setValue(settings.reinforcement || partialFactors.gammaS);
      this.settingsForm.get('steelProfile').setValue(settings.steelProfile || partialFactors.gammaP);
      this.settingsForm.get('combinationFactor').setValue(combinationFactor);
      this.settingsForm.get('unitSystem').setValue(unitSystem);
      this.settingsForm.get('usDeadLoad1').setValue(settings.usDeadLoad1 ? settings.usDeadLoad1 : SettingsComponent.DEFAULT_US_DEAD_LOAD_1_AISC);
      this.settingsForm.get('usDeadLoad2').setValue(settings.usDeadLoad2 ? settings.usDeadLoad2 : SettingsComponent.DEFAULT_US_DEAD_LOAD_2_AISC);
      this.settingsForm.get('usLiveLoad2').setValue(settings.usLiveLoad2 ? settings.usLiveLoad2 : SettingsComponent.DEFAULT_US_LIVE_LOAD_2_AISC);
      // tslint:disable-next-line:max-line-length
      this.settingsForm.get('usPhiElastic').setValue(settings.usPhiElastic ? settings.usPhiElastic : SettingsComponent.DEFAULT_US_PHI_ELASTIC);
      this.settingsForm.get('liveLoadReduction').setValue(settings.liveLoadReduction.toString());
      this.settingsForm.get('asPhiM').setValue(settings.asPhiM || SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiM);
      this.settingsForm.get('asPhiMb').setValue(settings.asPhiMb || SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiMb);
      this.settingsForm.get('asPhiVc').setValue(settings.asPhiVc || SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiVc);
      this.settingsForm.get('asPhiVm').setValue(settings.asPhiVm || SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiVm);
      this.settingsForm.get('asPhiS').setValue(settings.asPhiS || SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiS);
      this.settingsForm.get('asPhiC').setValue(settings.asPhiC || SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiC);
      this.settingsForm.get('asPhiVf').setValue(settings.asPhiVf || SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiVf);
      this.settingsForm.get('asPhiSC').setValue(settings.asPhiSC || SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiSC);
      this.settingsForm.get('asPhiSR').setValue(settings.asPhiSR || SettingsComponent.DEFAULT_COMB_FACTORS_ASNZS.ASPhiSR);
    } else {

      // Need to break fields section wise :ToDo
      this.settingsForm = this.fb.group({
        designCode: [{ value: designCode, disabled: SettingsComponent.ATTR_READONLY }, [Validators.required]],
        nationalAnnexes: [nationalAnnex, [Validators.required]],
        language: [lang, [Validators.required]],
        loadUnit: [{ value: loadUnit, disabled: SettingsComponent.ATTR_READONLY }, [Validators.required]],
        lengthUnit: [{ value: lengthUnit, disabled: SettingsComponent.ATTR_READONLY }, [Validators.required]],
        loadCategory: [loadCategory, [Validators.required]],
        ulsGammaG: [settings.ulsGammaG || partialFactors.gammaG, [Validators.required, CustomValidators.range([1, 2])]],
        ulsGammaQ: [settings.ulsGammaQ || partialFactors.gammaQ, [Validators.required, CustomValidators.range([1, 2])]],
        // tslint:disable-next-line:max-line-length
        structuralSteelSection: [settings.structuralSteelSection || partialFactors.gammaM0, [Validators.required, CustomValidators.range([1, 2])]],
        structuralSteelElement: [settings.structuralSteelElement || partialFactors.gammaM1, [Validators.required, CustomValidators.range([1, 2])]],
        concrete: [settings.concrete || partialFactors.gammaC, [Validators.required, CustomValidators.range([1, 2])]],
        connector: [settings.connector || partialFactors.gammaV, [Validators.required, CustomValidators.range([1, 2])]],
        reinforcement: [settings.reinforcement || partialFactors.gammaS, [Validators.required, CustomValidators.range([1, 2])]],
        steelProfile: [settings.steelProfile || partialFactors.gammaP, [Validators.required, CustomValidators.range([1, 2])]],
        combinationFactor: [combinationFactor, [Validators.required, CustomValidators.range([0, 1])]],
        unitSystem: [{ value: unitSystem, disabled: SettingsComponent.ATTR_READONLY }, [Validators.required]],
        usDeadLoad1: [{ value: settings.usDeadLoad1, disabled: SettingsComponent.ATTR_READONLY }, [Validators.required, Validators.max(2), Validators.min(1)]],
        usDeadLoad2: [{ value: settings.usDeadLoad2, disabled: SettingsComponent.ATTR_READONLY }, [Validators.required, Validators.max(2), Validators.min(1)]],
        usLiveLoad2: [{ value: settings.usLiveLoad2, disabled: SettingsComponent.ATTR_READONLY }, [Validators.required, Validators.max(2), Validators.min(1)]],
        usPhiElastic: [{ value: settings.usPhiElastic, disabled: SettingsComponent.ATTR_READONLY }, [Validators.required, Validators.min(0.5), Validators.max(1)]],
        liveLoadReduction: [{ value: settings.liveLoadReduction, disabled: SettingsComponent.ATTR_READONLY }, [Validators.required]],
        asPhiM: [{ value: settings.asPhiM }, [Validators.required, Validators.min(0.5), Validators.max(1)]],
        asPhiMb: [{ value: settings.asPhiMb }, [Validators.required, Validators.min(0.5), Validators.max(1)]],
        asPhiVc: [{ value: settings.asPhiVc }, [Validators.required, Validators.min(0.5), Validators.max(1)]],
        asPhiVm: [{ value: settings.asPhiVm }, [Validators.required, Validators.min(0.5), Validators.max(1)]],
        asPhiS: [{ value: settings.asPhiS }, [Validators.required, Validators.min(0.5), Validators.max(1)]],
        asPhiC: [{ value: settings.asPhiC }, [Validators.required, Validators.min(0.5), Validators.max(1)]],
        asPhiVf: [{ value: settings.asPhiVf }, [Validators.required, Validators.min(0.5), Validators.max(1)]],
        asPhiSC: [{ value: settings.asPhiSC }, [Validators.required, Validators.min(0.5), Validators.max(1)]],
        asPhiSR: [{ value: settings.asPhiSR }, [Validators.required, Validators.min(0.5), Validators.max(1)]]
      });
    }
  }

  private mapModel = (): Settings => {
    const settings: Settings = {
      'designCode': this.settingsForm.value.designCode.id,
      'countryNationalAnnexes': this.settingsForm.value.nationalAnnexes.id,
      'language': this.settingsForm.value.language.value,
      'loadUnit': SettingsComponent.ATTR_READONLY ? SettingsComponent.DEFAULT_LOAD_UNIT_ID : this.settingsForm.value.loadUnit.id,
      'lengthUnit': SettingsComponent.ATTR_READONLY ? SettingsComponent.DEFAULT_LENGTH_UNIT_ID : this.settingsForm.value.lengthUnit.id,
      'loadCategory': this.settingsForm.value.loadCategory.id,
      'ulsGammaG': this.settingsForm.value.ulsGammaG,
      'ulsGammaQ': this.settingsForm.value.ulsGammaQ,
      'structuralSteelSection': this.settingsForm.value.structuralSteelSection,
      'structuralSteelElement': this.settingsForm.value.structuralSteelElement,
      'concrete': this.settingsForm.value.concrete,
      'connector': this.settingsForm.value.connector,
      'reinforcement': this.settingsForm.value.reinforcement,
      'steelProfile': this.settingsForm.value.steelProfile,
      'combinationFactor': +this.settingsForm.value.combinationFactor,
      'trackingPolicy': this.user.defaultSettings ? this.user.defaultSettings.trackingPolicy : false,
      'name': this.user.authentication.userName,
      'customerId': this.user.authentication.customerOriginID,
      'region': window.navigator.language,
      'unit': this.settingsForm.getRawValue().unitSystem.id,
      'usDeadLoad1': this.settingsForm.value.usDeadLoad1,
      'usDeadLoad2': this.settingsForm.value.usDeadLoad2,
      'usLiveLoad2': this.settingsForm.value.usLiveLoad2,
      'usPhiElastic': this.settingsForm.value.usPhiElastic,
      'liveLoadReduction': this.settingsForm.value.liveLoadReduction,
      'asPhiMb': this.settingsForm.value.asPhiMb,
      'asPhiVc': this.settingsForm.value.asPhiVc,
      'asPhiVm': this.settingsForm.value.asPhiVm,
      'asPhiS': this.settingsForm.value.asPhiS,
      'asPhiC': this.settingsForm.value.asPhiC,
      'asPhiVf': this.settingsForm.value.asPhiVf,
      'asPhiSC': this.settingsForm.value.asPhiSC,
      'asPhiSR': this.settingsForm.value.asPhiSR,
      'asPhiM': this.settingsForm.value.asPhiM
    };

    return settings;
  }

  public onSaveSettings(): void {
    if (this.settingsForm.dirty) {
      if (this.settingsForm.valid) {
        const updatedModel = this.mapModel();
        this.sharedService.showLoader(true);
        this.settingsService.saveSettings(updatedModel)
          .subscribe(settingsResponse => {
            this.populateSettings(settingsResponse);
            this.sharedService.changeSettings(settingsResponse);
            this.onSaveSettingsComplete();
          },
            (error: any) => this.onSaveFailure(error)
            ,
            () => {
              this.sharedService.showLoader(false);
            });
      } else {
        if (!(<any>this.settingsForm.controls).combinationFactor.valid) {
          this.combinationFactorElement.nativeElement.focus();
        } else if (!(<any>this.settingsForm.controls).ulsGammaG.valid) {
          this.ulsGammaGElement.nativeElement.focus();
        } else if (!(<any>this.settingsForm.controls).ulsGammaQ.valid) {
          this.ulsGammaQElement.nativeElement.focus();
        } else if (!(<any>this.settingsForm.controls).structuralSteelSection.valid) {
          this.sectionResistanceElement.nativeElement.focus();
        } else if (!(<any>this.settingsForm.controls).structuralSteelElement.valid) {
          this.elementResistanceElement.nativeElement.focus();
        } else if (!(<any>this.settingsForm.controls).steelProfile.valid) {
          this.steelProfileElement.nativeElement.focus();
        } else if (!(<any>this.settingsForm.controls).reinforcement.valid) {
          this.reinforcementSafetyElement.nativeElement.focus();
        } else if (!(<any>this.settingsForm.controls).concrete.valid) {
          this.concreteElement.nativeElement.focus();
        } else if (!(<any>this.settingsForm.controls).connector.valid) {
          this.connectorResistanceElement.nativeElement.focus();
        }
      }
    }
  }
  initializeSettingForm() {
    this.loadForm(<Settings>{
      designCode: 1,
      countryNationalAnnexes: 1,
      language: 'en',
      loadUnit: 0,
      lengthUnit: 0,
      loadCategory: 0,
      ulsGammaG: 0,
      ulsGammaQ: 0,
      combinationFactor: null,
      structuralSteelSection: 0,
      structuralSteelElement: 0,
      concrete: 0,
      connector: 0,
      reinforcement: 0,
      steelProfile: 0,
      unit: 0,
      usDeadLoad1: 0,
      usDeadLoad2: 0,
      usLiveLoad2: 0,
      usPhiElastic: 0,
      liveLoadReduction: false
    });
  }

  activateValueChanges() {
    this.settingsForm.get('language').valueChanges.pipe(debounceTime(500)).subscribe(lang => {
      this.onLanguageChange(lang.value);
    });

    this.settingsForm.get('designCode').valueChanges.pipe(debounceTime(500)).subscribe(designCode => {
      this.onDesignCodeChange(designCode.id);
    });

    this.settingsForm.get('unitSystem').valueChanges.pipe(debounceTime(500)).subscribe(unitSystem => {
      this.onUnitSystemChange(unitSystem.id);
    });

    merge(this.settingsForm.get('nationalAnnexes').valueChanges,
      this.settingsForm.get('loadCategory').valueChanges).pipe(debounceTime(500)).subscribe((e) => {
        const nationalAnnex = this.settingsForm.value.nationalAnnexes;
        const loadCategory = this.settingsForm.value.loadCategory;
        const designCode = this.settingsForm.value.designCode;
        if (designCode.id === 1) {
          this.setSafetyFactors(nationalAnnex, loadCategory);
        }
        if (designCode.id === 2) {
          this.settingsForm.get('liveLoadReduction').setValue(loadCategory.liveLoadReduction.toString());
        }
      });
  }

  private onLanguageChange(language) {
    if (typeof language === 'string') {
      this.sharedService.changeLanguage(language);
    } else {
      this.sharedService.changeLanguage(language.value);
    }
  }

  private onDesignCodeChange(designCode) {
    const selectedDesignCode = this.designCodes.find(dc => dc.id === designCode);
    this.settingsForm.get('unitSystem').setValue(this.unitSystems.find(us => us.id === selectedDesignCode.defaultUnitSystem));
    // tslint:disable-next-line:max-line-length
    const filteredLoadCategories = this.dropDownOptions.loadCategories.filter(c => c.designCodeId === designCode);
    this.loadCategories = _.sortBy(filteredLoadCategories, [function (o) { return o.id; }]);
    this.settingsForm.get('loadCategory').setValue(filteredLoadCategories[0]);

    this.nationalAnnexes = NationalAnnexSortingHelper.getSortedNationalAnnexes(this.dropDownOptions.nationalAnnexes, designCode);
    this.settingsForm.get('nationalAnnexes').setValue(this.nationalAnnexes[0]);
    this.setULSCombFactors(designCode);
    this.setCapacityFactors(designCode);
  }

  private setCapacityFactors(designCode: number) {
    if (designCode !== 3) {
      return;
    }
    const factors = this.getPartialFactors(this.settingsForm.get('nationalAnnexes').value.id);
    this.settingsForm.get('asPhiM').setValue(factors.asPhiM);
    this.settingsForm.get('asPhiMb').setValue(factors.asPhiMb);
    this.settingsForm.get('asPhiVc').setValue(factors.asPhiVc);
    this.settingsForm.get('asPhiVm').setValue(factors.asPhiVm);
    this.settingsForm.get('asPhiS').setValue(factors.asPhiS);
    this.settingsForm.get('asPhiC').setValue(factors.asPhiC);
    this.settingsForm.get('asPhiVf').setValue(factors.asPhiVf);
    this.settingsForm.get('asPhiSC').setValue(factors.asPhiSC);
    this.settingsForm.get('asPhiSR').setValue(factors.asPhiSR);
  }

  private onUnitSystemChange(unitSystemId) {
    this.setUnits(this.dropDownOptions, unitSystemId);
    this.settingsForm.get('loadUnit').setValue(this.loadUnits[0]);
    this.settingsForm.get('lengthUnit').setValue(this.lengthUnits[0]);
  }

  public goBacktoPrevious(): void {

    if (!this.returnUrl || this.returnUrl === '/settings' || this.returnUrl === '/logout') {
      this.router.navigate([SettingsComponent.HOME]);
    } else {
      try {
        if (_.includes(this.returnUrl, 'isNew')) {
          this.returnUrl = _.replace(this.returnUrl, new RegExp('isNew=true', 'g'), 'isNew=false');
        }
        this.router.navigateByUrl(this.returnUrl);
      } catch (err) {
        this.router.navigate([SettingsComponent.HOME]);
      }
    }
  }

  public goToProjectList(): void {
    this.router.navigate([SettingsComponent.HOME]);
  }

  private onSaveSettingsComplete(): void {
    this.sharedService.popSuccess('settings-save-success');
    this.settingsForm.markAsPristine({ onlySelf: true });
    this.router.navigate([SettingsComponent.HOME]);
  }

  private onSaveFailure(error): void {
    this.sharedService.popFailure('settings-save-failure');
  }

  private onGetFailure(error): void {
    this.sharedService.popFailure('settings-open-failure');
  }
}
