import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { SharedModule } from '../shared/shared.module';
import { SoftwarePopupComponent } from './software-popup.component';
import {ButtonModule, CheckboxModule} from 'hilti-ui-library';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    DialogModule,
    FormsModule,
    CheckboxModule,
    ButtonModule
  ],
  declarations: [
    SoftwarePopupComponent
  ],
  exports: [
    SoftwarePopupComponent
  ]
})
export class SoftwarePopupModule {};
