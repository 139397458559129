import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppConstants } from './common/AppConstants';
import { SharedService } from './service/common/shared.service';
import { UserService } from './service/common/user.service';
import { AppStorageService } from './service/util/app-storage.service';

@Injectable()
export class UserAgreementAcceptGaurd implements CanActivate {
    constructor(private sharedService: SharedService, private user: UserService, public appStorageService: AppStorageService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.user.isEulaAccepted) {
            if (this.appStorageService.get(AppConstants.HVB_EULA) || this.appStorageService.get(AppConstants.HVB_PREVIOUS_USER)) {
                return true;
            } else {
                this.sharedService.showEulaDialog(true);
                return this.sharedService.$showEulaDialog;
            }
        } else {
            return true;
        }
    }
}
