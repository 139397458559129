import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'app/service/config.service';
import { UnitHelper } from '../../common/unit.helper';
import * as models from '../../models/models';
import { CalculationsService } from '../calculations.service';

@Component({
  selector: 'app-calculate',
  templateUrl: './calculate.component.html',
  styleUrls: ['./calculate.component.scss']
})
export class CalculateComponent implements OnInit {
  private compositeCrossSectionDrawing = '';
  private beamGeometryDrawingStream = '';
  public calculateResult: models.Calculate.CalculationResult;
  private createCommand: models.Calculate.CreateCommand;
  public error = false;
  public errorNotStop = false;
  public pdfError = false;
  public showHtml: boolean;
  private connectorType: any;
  private totalLoadDeflection: any;
  private deflectionLimit: any;
  private errorLabels: any[] = [];
  private listOfNotes: string[] = [];
  private errorMessages: any[] = [];
  private errorMessagesNotStop: any[] = [];
  private errorLabelsNotStop: any[] = [];
  private zones: any[] = [];
  private connectionZones: string;
  public enableReportButton = true;
  public UnitHelper = UnitHelper;
  public showWatermark = false;
  public showConnectionDegree = true;
  public showLimConnectionDegree = false;
  public showMinConnectionDegree = false;
  public showUserConnectionDegree = false;
  public showScopeExtensionsWarningOnTop = false;
  public showScopeExtensionsWarningInZones = false;
  public showLtbCriterionWarning = false;

  @Input() private calculateInput: models.Calculate.CreateCommand;
  @Input() private connectorTypes: any[] = [];
  @Input() private totalLoadDeflections: any[] = [];
  @Input() private deflectionLimits: any[] = [];
  @Input() private designCode: number;
  @Input() private unitSystem: models.UnitSystem;
  @Input() private unitSystemId: number;
  @Input() private validationRanges: models.ErrorCodesTranslationValidationRanges;

  constructor(private configService: ConfigService, private service: CalculationsService, private translate: TranslateService) {
    this.showHtml = false;
    this.showWatermark = this.configService.config.beta;
  }

  ngOnInit() {
    this.error = false;
  }

  public updateErrors(errors: string[]) {
    if (errors == null || errors.length === 0) {
      errors = [];
      errors.push('cannot-calculate');
    }
    this.errorLabels = errors;
    console.log('Errors = ', errors);
    this.updateZones([]);
    this.updateConnectionZones('');
    this.error = true;
    this.pdfError = true;
    this.hidePanel();
  }

  public updateZones(zones: any[]) {
    this.zones = zones;
  }

  public updateConnectionZones(drawing: string) {
    this.connectionZones = drawing;
  }

  public updateCalculations(command: models.Calculate.CreateCommand, calculation: models.Calculate.CalculationResult): void {
    this.createCommand = command;
    this.calculateResult = calculation;
    this.errorLabels = [];
    this.errorMessages = [];
    this.errorLabelsNotStop = [];
    this.errorMessagesNotStop = [];
    this.showLimConnectionDegree = false;
    this.showMinConnectionDegree = false;
    this.showUserConnectionDegree = false;
    this.showConnectionDegree = true;
    this.showScopeExtensionsWarningInZones = false;
    this.showScopeExtensionsWarningOnTop = false;

    if (this.calculateResult.errorCodesNotStop.length > 0) {
      this.errorLabelsNotStop = this.calculateResult.errorCodesNotStop.map(item => 'error-code-' + item);
      this.errorMessagesNotStop = this.errorLabelsNotStop.map(item => this.translate.instant(item));
      this.errorNotStop = true;
    } else {
      this.errorNotStop = false;
      this.errorLabelsNotStop = [];
    }

    if (this.calculateResult.errorCodes.length > 0) {
      this.enableReportButton = false;
      this.errorLabels = this.calculateResult.errorCodes.map(item => 'error-code-' + item);
      this.errorMessages = this.errorLabels.map(item => this.translate.instant(item, this.validationRanges));
      // Calculations were successful but there was an error in generating report
      // We can display calculations
      this.pdfError = (this.errorLabels.length === 1 && (this.errorLabels[0] === 'error-code-998' ||
        this.errorLabels[0] === 'error-code-998'));
      this.error = true;
      if (this.error && this.pdfError) {
        this.showPanel();
      } else {
        this.hidePanel();
      }
    } else {
      this.error = false;
      this.pdfError = false;
      this.errorLabels = [];
      this.showPanel();

      if (command.generalInformation.scopeExtensions.extension1
        && command.generalInformation.scopeExtensions.extension2
        && calculation.shearConnectionResult.listOfNumberOfRows) {

        const listNumberOfRows = calculation.shearConnectionResult.listOfNumberOfRows.slice(1);
        this.showScopeExtensionsWarningInZones = listNumberOfRows.some(number => number === 1)
          && listNumberOfRows.some(number => number !== 1);

        this.showScopeExtensionsWarningOnTop =  !this.showScopeExtensionsWarningInZones && (listNumberOfRows.every(number => number === 1)
          || (command.steelProfile.flangeThickness >= 0.006 && command.steelProfile.flangeThickness < 0.008));
      }
    }

    if (this.calculateResult.shearConnectionResult.limConnectionDegree > this.calculateResult.shearConnectionResult.connectionDegree) {
      this.showConnectionDegree = false;
      if (this.calculateInput.shearConnector.connectionDegreeValue === 0
        && this.calculateResult.shearConnectionResult.connectionDegree >= this.calculateResult.shearConnectionResult.minConnectionDegree) {
        this.showLimConnectionDegree = true;
      } else {
        if (this.createCommand.shearConnector.connectionDegreeValue === 2) {
          if (this.calculateResult.shearConnectionResult.connectionDegree < this.calculateResult.shearConnectionResult.minConnectionDegree
            && this.calculateResult.shearConnectionResult.connectionDegree < this.createCommand.shearConnector.userConnectionDegree) {
            this.showUserConnectionDegree = true;
          }
          if (this.calculateResult.shearConnectionResult.connectionDegree >= this.calculateResult.shearConnectionResult.minConnectionDegree
            && this.calculateResult.shearConnectionResult.connectionDegree < this.createCommand.shearConnector.userConnectionDegree) {
            this.showUserConnectionDegree = true;
          }
        } else {
          if (this.calculateResult.shearConnectionResult.minConnectionDegree >
             this.calculateResult.shearConnectionResult.connectionDegree) {
            this.showMinConnectionDegree = true;
          }
        }
      }
    }
    if (this.calculateInput.shearConnector) {
      this.connectorType = this.connectorTypes.filter(item =>
        item.value === +this.calculateInput.shearConnector.connectorTypeValue)[0];
    }
    if (this.calculateInput && this.calculateInput.deformations && this.calculateInput.deformations.compositeDeflectionLimit) {
      this.deflectionLimit = this.deflectionLimits.filter(item => item.value ===
        this.calculateInput.deformations.compositeDeflectionLimit)[0];
    }
    if (this.calculateInput && this.calculateInput.deformations && this.calculateInput.deformations.totalDeflectionLimit) {
      this.totalLoadDeflection = this.deflectionLimits.filter(item => item.value ===
        this.calculateInput.deformations.totalDeflectionLimit)[0];
    }
    if (this.calculateResult.listOfNotes) {
      this.listOfNotes = this.calculateResult.listOfNotes;
    }
    if (this.calculateInput.deformations.performLateralTorsionBucklingCheck) {
      this.showLtbCriterionWarning = true;
    } else {
      this.showLtbCriterionWarning = false;
    }
  }

  public totalDeflectionValid() {
    let valid = true;
    valid = this.calculateResult.finalStageResult && this.calculateResult.finalStageResult.totalDeflectionMax &&
      (this.calculateResult.finalStageResult.totalDeflectionMax * 1000) >
      ((this.calculateInput.beamGeometry.beamLength * 1000) / this.calculateInput.deformations.totalDeflectionLimit) ? false : true;
    return valid;
  }

  public totalDeflectionUnderLiveLoadsValid() {
    let valid = true;
    valid = this.calculateResult.finalStageResult && this.calculateResult.finalStageResult.liveLoadDeflectionMax &&
      (this.calculateResult.finalStageResult.liveLoadDeflectionMax * 1000) >
      ((this.calculateInput.beamGeometry.beamLength * 1000) / this.calculateInput.deformations.compositeDeflectionLimit) ? false : true;
    return valid;
  }

  public getGTLSSymbol(val1, label) {
    const minCalculationAssumption = 0.0001;
    // tslint:disable-next-line:no-eval
    const execVal1 = eval(val1);
    let execVal2 = 1;
    if (label && label.replace) {
      // tslint:disable-next-line:no-eval
      execVal2 = eval(label.replace('L', this.calculateInput.beamGeometry.beamLength * 1000));
    } else {
      // tslint:disable-next-line:no-eval
      execVal2 = eval(label);
    }

    if (execVal1 > execVal2 + minCalculationAssumption) {
      return '>';
    } else if (execVal1 + minCalculationAssumption < execVal2) {
      return '<';
    } else {
      return '=';
    }

  }

  public showPanel(): void {
    this.showHtml = true;
  }

  public hidePanel(): void {
    this.showHtml = false;
  }
}
