import { Component, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'force-unit',
  templateUrl: './force.component.html',
  styleUrls: ['./force.component.scss']
})
export class ForceComponent {
  @Input()
  public forceUnitTitle: string;
  @Input()
  public lengthUnitTitle: string;
  @Input()
  public exponent: number;

  constructor() { }
}
