import { EulaService } from './eula.service';
import { EulaComponent } from './eula.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        DropDownsModule,
        FormsModule
    ],
    declarations: [
        EulaComponent
    ],
    exports: [
        EulaComponent
    ],
    providers: [
        EulaService
    ]
})
export class EulaModule { }
