import { ForceComponent } from './../units/force/force.component';
import { UserAgreementAcceptGaurd } from './../user-agreement-acceptguard.service';
import { NgModule } from '@angular/core';
import { SharedModule } from './../shared/shared.module';
import { RouterModule } from '@angular/router';
import { SubProjectListComponent } from './sub-project-list.component';
import { SubProjectComponent } from './sub-project.component';
import { SubProjectService } from './sub-project.service';
import { GridModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ApplicationOptionsService } from '../service/common/application-options.service';
import { SettingsService } from '../settings/settings.service';
import { DialogModule } from '@progress/kendo-angular-dialog';

import { CalculateComponent } from '../calculations/calculate/calculate.component';
import { CalculationsService } from './../calculations/calculations.service';
import { RouteGuard } from './../service/common/routeguard.service';
import { SubProjectSaveGuard } from './sub-project-saveguard.service';
import { PointloadsComponent } from './pointloads/pointloads.component';
import { SurfaceloadsComponent } from './surfaceloads/surfaceloads.component';
import { LineloadsComponent } from './lineloads/lineloads.component';
import { ValuesBetweenPipe } from './valuesBetween.pipe';
import {ButtonModule, CheckboxModule, ContentSectionModule, RadioButtonModule} from 'hilti-ui-library';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild([
      {
        path: 'subprojects',
        component: SubProjectListComponent,
        canActivate: [RouteGuard, UserAgreementAcceptGaurd]
      },
      {
        path: 'subproject',
        component: SubProjectComponent,
        canActivate: [RouteGuard, UserAgreementAcceptGaurd],
        canDeactivate: [SubProjectSaveGuard]
      },
      {
        path: 'subproject/:id',
        component: SubProjectComponent,
        canActivate: [RouteGuard, UserAgreementAcceptGaurd],
        canDeactivate: [SubProjectSaveGuard]
      },
      {
        path: 'subproject/:projectid',
        component: SubProjectComponent,
        canActivate: [RouteGuard, UserAgreementAcceptGaurd],
        canDeactivate: [SubProjectSaveGuard]
      }
    ]),
    GridModule,
    DropDownsModule,
    LayoutModule,
    ReactiveFormsModule,
    FormsModule,
    DialogModule,
    ButtonModule,
    CheckboxModule,
    ContentSectionModule,
    RadioButtonModule,
  ],
  declarations: [
    ValuesBetweenPipe,
    SubProjectListComponent,
    SubProjectComponent,
    CalculateComponent,
    PointloadsComponent,
    SurfaceloadsComponent,
    LineloadsComponent,
    ForceComponent,
  ],
  providers: [
    SubProjectService,
    ApplicationOptionsService,
    CalculationsService,
    SettingsService,
    SubProjectSaveGuard,
    UserAgreementAcceptGaurd
  ],
  exports: [
    SubProjectListComponent,
    SubProjectComponent
  ]
})
export class SubProjectModule { }
