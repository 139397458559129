import { AppConstants } from './AppConstants';
import * as models from '../models/models';
import { Injectable } from '@angular/core';

@Injectable()
export class UnitHelper {

  public static getDbToDisplayForceFactor(loadUnit: number): number {
    switch (loadUnit) {
      case AppConstants.UNIT_KN_ID:
        return 1;
      case AppConstants.UNIT_daN_ID:
        return 10;
      case AppConstants.UNIT_N_ID:
        return 1000;
      case AppConstants.UNIT_LBF_ID:
        return 1000;
      case AppConstants.UNIT_KIP_ID:
        return 1;
      default:
        return 1;
    }
  }

  public static getDisplayToDbForceFactor(loadUnit: number): number {
    switch (loadUnit) {
      case AppConstants.UNIT_KN_ID:
        return 1;
      case AppConstants.UNIT_daN_ID:
        return 0.01;
      case AppConstants.UNIT_N_ID:
        return 0.001;
      case AppConstants.UNIT_LBF_ID:
        return 0.001;
      case AppConstants.UNIT_KIP_ID:
        return 1;
      default:
        return 1;
    }
  }

  private static getDisplayFromApiForceFactor(loadUnit: number): number {
    switch (loadUnit) {
      case AppConstants.UNIT_KN_ID:
        return 0.001;
      case AppConstants.UNIT_daN_ID:
        return 0.1;
      case AppConstants.UNIT_N_ID:
        return 1;
      case AppConstants.UNIT_LBF_ID:
        return 12 ** 2;
      case AppConstants.UNIT_KIP_ID:
        return 0.144;
      default:
        return 1;
    }
  }

  private static getApiFromDisplayForceFactor(loadUnit: number): number {
    switch (loadUnit) {
      case AppConstants.UNIT_KN_ID:
        return 1000;
      case AppConstants.UNIT_daN_ID:
        return 10;
      case AppConstants.UNIT_N_ID:
        return 1;
      case AppConstants.UNIT_LBF_ID:
        return 1 / 12 ** 2;
      case AppConstants.UNIT_KIP_ID:
        return 6.9444444;
      default:
        return 1;
    }
  }

  private static getApiFromPointLoad(loadUnit: number): number {
    switch (loadUnit) {
      case AppConstants.UNIT_KN_ID:
        return 1000;
      case AppConstants.UNIT_daN_ID:
        return 10;
      case AppConstants.UNIT_N_ID:
        return 1;
      case AppConstants.UNIT_LBF_ID:
        return 1;
      case AppConstants.UNIT_KIP_ID:
        return 1000;
      default:
        return 1;
    }
  }

  private static getApiFromLoadsForce(loadUnit: number): number {
    switch (loadUnit) {
      case AppConstants.UNIT_KN_ID:
        return 1000;
      case AppConstants.UNIT_daN_ID:
        return 10;
      case AppConstants.UNIT_N_ID:
        return 1;
      case AppConstants.UNIT_LBF_ID:
        return 1 / 12;
      case AppConstants.UNIT_KIP_ID:
        return 83.34444;
      default:
        return 1;
    }
  }

  public static roundLoadFromApi = (num, loadUnit: number): number => {
    num = (num * UnitHelper.getDisplayFromApiForceFactor(loadUnit));
    return Math.round(num * 100000) / 100000; // Javascript rounding fix - Upto 5 decimal points
  }

  public static roundLoadToApi = (num, loadUnit: number): number => {
    num = (num * UnitHelper.getApiFromDisplayForceFactor(loadUnit));
    return Math.round(num * 100000) / 100000; // Javascript rounding fix - Upto 4 decimal points
  }

  public static roundPatchLoad = (num, loadUnit: number): number => {
    num = (num * UnitHelper.getApiFromLoadsForce(loadUnit));
    return Math.round(num * 100000) / 100000;
  }
  public static roundPointLoad = (num, loadunit: number): number => {
    num = (num * UnitHelper.getApiFromPointLoad(loadunit));
    return Math.round(num * 100000) / 100000;
  }

  public static roundLoadFromDisplay = (num, loadUnit: number): number => {
    num = (num * UnitHelper.getDisplayFromApiForceFactor(loadUnit));
    return Math.round(num * 100000) / 100000; // Javascript rounding fix - Upto 4 decimal points
  }

  public static roundLoadToDb = (num, loadUnit: number): number => {
    num = num * UnitHelper.getDisplayToDbForceFactor(loadUnit);
    return Math.round(num * 100000) / 100000; // Javascript rounding fix - Upto 5 decimal points
  }

  /**
   * Converts length in specified unit
   * @param num - length which will be converted
   * @param unit - destination unit system (1: Metric Unit system, 2: US customary)
   * @param sourceUnit - Current unit for 'num'
   * @param destination - for metric only, describes output unit mm or meters.
   */
  public static roundToUniverseLength = (num, unit: number, sourceUnit = undefined, destination: 'mm' | 'm' = 'm'): number => {
    if (sourceUnit !== undefined) {
      if (unit === 2 && sourceUnit === 1) {
        return Math.round(UnitHelper.convertMMToInch(num) * 100000) / 100000;
      }
    }
    if (destination === 'm') {
      num = unit === AppConstants.UNIT_SYSTEM_MI ? num / 1000 : num;
    }
    return Math.round(num * 100000) / 100000; // Javascript rounding fix - Upto 5 decimal points
  }

  public static getDensity(num, unit: number) {
    return unit === AppConstants.UNIT_SYSTEM_MI ? num : num / Math.pow(12, 3);
  }

  public static getConcreteStrength(num, unit: number) {
    if (unit === AppConstants.UNIT_SYSTEM_MI) {
      return num * 1000000;
    } else {
      return num;
    }
  }

  public static roundToNonUniverseLength = (num, unit: number): number => {
    num = unit === AppConstants.UNIT_SYSTEM_MI ? num * 1000 : num * 12;
    return Math.round(num * 100000) / 100000; // Javascript rounding fix - Upto 5 decimal points
  }

  public static roundYieldStrengthFromMpa = (num): number => {
    return num * 1000000; // Multiply by 10 power 6 to convert from Mpa to Pa
  }

  public static getYieldStrength(num, unitSystem, sourceUnitSystem) {
    if (sourceUnitSystem === unitSystem) {
      return unitSystem === AppConstants.UNIT_SYSTEM_MI ? UnitHelper.roundYieldStrengthFromMpa(num) : num * 1000;
    } else {
      if (sourceUnitSystem === AppConstants.UNIT_SYSTEM_MI && unitSystem === AppConstants.UNIT_SYSTEM_US) {
        return UnitHelper.convertMpaToKsi(num) * 1000;
      }
      if (sourceUnitSystem === AppConstants.UNIT_SYSTEM_US && unitSystem === AppConstants.UNIT_SYSTEM_MI) {
        return UnitHelper.convertKsiToMpa(num);
      }
    }
  }

  private static getForceUnitTitle = (loadUnit: number): string => {
    switch (loadUnit) {
      case AppConstants.UNIT_KN_ID:
        return AppConstants.UNIT_KN_STR;
      case AppConstants.UNIT_daN_ID:
        return AppConstants.UNIT_daN_STR;
      case AppConstants.UNIT_N_ID:
        return AppConstants.UNIT_N_STR;
      case AppConstants.UNIT_LBF_ID:
        return AppConstants.UNIT_LBF_STR;
      case AppConstants.UNIT_KIP_ID:
        return AppConstants.UNIT_KIP_STR;
      default:
        return AppConstants.UNIT_KN_STR;
    }
  }

  public static lbfft2TolbfInch2(num) {
    return num / (12 ** 2);
  }

  public static getStressValue(num: number, unitSystem: number, sourceUnitSystem) {
    if (sourceUnitSystem === unitSystem) {
      return unitSystem === AppConstants.UNIT_SYSTEM_MI ? num : this.lbfft2TolbfInch2(num);
    } else if (sourceUnitSystem === AppConstants.UNIT_SYSTEM_MI && unitSystem === AppConstants.UNIT_SYSTEM_US) {
      return num * 0.0014223369;
    } else if (sourceUnitSystem === AppConstants.UNIT_SYSTEM_US && unitSystem === AppConstants.UNIT_SYSTEM_MI) {
      return UnitHelper.convertPsfToKgm2(num);
    }
  }

  public static getReportLengthValue(num: number, unitSystemId: number, fractionDigits = 3) {
    if (unitSystemId === 1) {
      return (<any>num.toFixed(fractionDigits)) * 1000;
    } else {
      return num.toFixed(fractionDigits);
    }
  }

  private static getLengthUnitTitle = (lengthUnit: number): string => {
    switch (lengthUnit) {
      case AppConstants.UNIT_M_ID:
        return AppConstants.UNIT_M_STR;
      case AppConstants.UNIT_CM_ID:
        return AppConstants.UNIT_CM_STR;
      case AppConstants.UNIT_MM_ID:
        return AppConstants.UNIT_MM_STR;
      case AppConstants.UNIT_FT_ID:
        return AppConstants.UNIT_FT_STR;
      default:
        return AppConstants.UNIT_M_STR;
    }
  }

  private static getDimensionUnitTitle = (unit: number): string => {
    switch (unit) {
      case AppConstants.UNIT_SYSTEM_MI:
        return AppConstants.UNIT_MM_STR;
      case AppConstants.UNIT_SYSTEM_US:
        return AppConstants.UNIT_IN_STR;
      default:
        return AppConstants.UNIT_MM_STR;
    }
  }

  private static getDensityUnitTitle = (unit: number): string => {
    switch (unit) {
      case AppConstants.UNIT_SYSTEM_MI:
        return AppConstants.UNIT_KG_M_STR;
      case AppConstants.UNIT_SYSTEM_US:
        return AppConstants.UNIT_LB_FT_STR;
      default:
        return AppConstants.UNIT_KG_M_STR;
    }
  }

  private static getStressUnitTitle = (unit: number): string => {
    switch (unit) {
      case AppConstants.UNIT_SYSTEM_MI:
        return AppConstants.UNIT_MPA_STR;
      case AppConstants.UNIT_SYSTEM_US:
        return AppConstants.UNIT_PSI_STR;
      default:
        return AppConstants.UNIT_MPA_STR;
    }
  }

  private static getStressUnitForMaterialsTitle = (unit: number): string => {
    switch (unit) {
      case AppConstants.UNIT_SYSTEM_MI:
        return AppConstants.UNIT_MPA_STR;
      case AppConstants.UNIT_SYSTEM_US:
        return AppConstants.UNIT_KSI_STR;
      default:
        return AppConstants.UNIT_MPA_STR;
    }
  }

  public static getUnitSystem = (unit: number, loadUnit: number, lengthUnit: number): models.UnitSystem => {
    return {
      'lengthUnitTitle': UnitHelper.getLengthUnitTitle(lengthUnit),
      'forceUnitTitle': UnitHelper.getForceUnitTitle(loadUnit),
      'dimensionUnitTitle': UnitHelper.getDimensionUnitTitle(unit),
      'densityUnitTitle': UnitHelper.getDensityUnitTitle(unit),
      'stressUnitTitle': UnitHelper.getStressUnitTitle(unit),
      'surfaceWeightUnitTitle': UnitHelper.getDensityUnitTitle(unit),
      'stressUnitForMaterialsTitle': UnitHelper.getStressUnitForMaterialsTitle(unit)
    };
  }

  public static convertMMToInch(value: number) {
    const num = value * 0.0393701;
    return Math.round(num * 100000) / 100000; // Javascript rounding fix - Upto 5 decimal points
  }
  public static convertInchToMm(value: number) {
    const num = value * 25.4;
    return Math.round(num * 100000) / 100000;
  }

  public static ftToInches(value: number) {
    const num = value * 12;
    return Math.round(num * 100000) / 100000;
  }

  public static convertKgM2ToLbFt2(value: number): number {
    return value * 0.20482;
  }

  public static convertMpaToKsi(value: number): number {
    return value * 0.1450377;
  }

  private static convertKsiToMpa(num: number) {
    return num * 6.89476;
  }

  public static convertPsfToKgm2(num: number) {
    return num * 4.8824276270581;
  }
}
