import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { GridModule } from '@progress/kendo-angular-grid';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { PersonalDataService } from './personal-data.service';
import { SharedModule } from './../../shared/shared.module';
import { PersonalDataComponent } from './personal-data.component';
import { SettingsService } from '../../settings/settings.service';
import { RouteGuard } from './../../service/common/routeguard.service';

@NgModule({
  imports: [
    SharedModule,
    ReactiveFormsModule,
    RouterModule.forChild([
      { path: 'gdpr', component: PersonalDataComponent, canActivate: [RouteGuard] }
    ]),
    GridModule,
    LayoutModule,
    DialogModule
  ],
  declarations: [
    PersonalDataComponent
  ],
  providers: [
    SettingsService,
    PersonalDataService
  ],
  exports: [
    PersonalDataComponent
  ]
})
export class PersonalDataModule { };
