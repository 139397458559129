
import { Observable, of, combineLatest } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { TranslateLoader } from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';

export class TranslationLoader implements TranslateLoader {
  constructor(private http: HttpClient) { }

  public getTranslation(lang: string): Observable<any> {
    const language = lang.substr(0, 2);
    const textTranslation = this.http.get(`/assets/i18n/${language}.json`).pipe(
      catchError((err) => of({})), );
    const infoTranslation = this.http.get(`/assets/i18n/info/info_${language}.json`).pipe(
      catchError((err) => of({})), );
    return combineLatest([textTranslation, infoTranslation])
      .pipe(map(([a, b]) => {
        return Object.assign({}, a, b);
      }));
  }
}
