import { SurveyService } from './survey.service';
import { SurveyComponent } from './survey.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from '@progress/kendo-angular-dialog';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        DialogModule
    ],
    declarations: [
        SurveyComponent
    ],
    exports: [
        SurveyComponent
    ],
    providers: [
        SurveyService
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class SurveyModule { }
