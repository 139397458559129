import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ProjectComponent } from './project.component';
import { Observable } from 'rxjs';

@Injectable()
export class ProjectSaveGuard implements CanDeactivate<ProjectComponent> {
    constructor() {

    }

    canDeactivate(component: ProjectComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        if (component.projectForm.dirty) {
            component.showSaveOnDestroyDialog = true;
            return component.$saveOnLeaveObs;
        } else {
            return true;
        }
    }
}
