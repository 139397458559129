import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AppStorageService } from './service/util/app-storage.service';
import { AppConstants } from './common/AppConstants';
@Component({
    template: ` <h2>{{message | translate}}</h2><br/><button (click)="goToProjects()">Login</button>`
})
export class LogoutComponent {
    public message = 'logout-success';
    constructor(private translate: TranslateService, private router: Router, private appStorageService: AppStorageService) {
        if (!this.translate.currentLang) {
            this.translate.use(translate.getBrowserLang());
        }
    }

    goToProjects() {
        this.appStorageService.set(AppConstants.ReturnUrl, location.origin);
        window.location.href = location.origin;
    }
}
